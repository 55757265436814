const renderUpload = () => {
    const individualUploadActive = document.querySelector('.individual-upload__wrapper--active') as HTMLDivElement;
    individualUploadActive?.focus();

    if (individualUploadActive) {
        const individualUploadBackButtons: NodeListOf<Element> =
            individualUploadActive.querySelectorAll('.individual-upload__back');
        const individualAuth: HTMLDivElement = document.querySelector('.individual-auth');
        const individualUploadSubmit: HTMLButtonElement =
            individualUploadActive.querySelector('.individual-upload__submit');
        const individualUploadButtons: NodeListOf<HTMLInputElement> = individualUploadActive.querySelectorAll(
            '.individual-upload__upload-input',
        );
        const individualCaptureButtons: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__capture-input',
        );
        const individualUploadItems: NodeListOf<Element> =
            individualUploadActive.querySelectorAll('.individual-upload__item');
        const individualUploadMains: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__upload-main',
        );
        const previewContainers: NodeListOf<Element> =
            individualUploadActive.querySelectorAll('.individual-upload__preview');
        const previewImages: NodeListOf<HTMLImageElement> = individualUploadActive.querySelectorAll(
            '.individual-upload__preview-image',
        );
        const previewPdfs: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__preview-pdf',
        );
        const previewFileNames: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__preview-name',
        );
        const previewFileSizes: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__preview-size',
        );
        const individualUploadAreas: NodeListOf<Element> =
            individualUploadActive.querySelectorAll('.individual-upload__upload');
        const invalidUploadStatuses: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__status-invalid',
        );
        const validUploadStatuses: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__status-valid',
        );
        const individualUploadPreviewDeleteButton: NodeListOf<Element> = individualUploadActive.querySelectorAll(
            '.individual-upload__preview-delete',
        );

        const fileValidations = individualUploadActive
            .querySelector('.individual-upload__upload-input')
            .getAttribute('accept');
        let uploadedFiles = [];

        const validateFile = (file: File): boolean => {
            const extension = file.name.split('.').pop()?.toLowerCase();
            const maxSize = 8000000;

            return extension && fileValidations.includes('.' + extension) && file.size <= maxSize;
        };

        const kbToMb = (bytes: number): string => {
            const megabytes = bytes / (1024 * 1024);
            return megabytes.toFixed(1) + ' MB';
        };

        const handleFile = (event, i: number, isDropped = false) => {
            if (isDropped) {
                const dt = event.dataTransfer;
                const files = dt.files;

                if (validateFile(files[0]) === true) {
                    const filePath = files[0].name;
                    let fileName = filePath.split('\\').pop().split('/').pop();
                    let fullFileName = filePath.split('\\').pop().split('/').pop();
                    const fileExtension = fileName.split('.').pop().toUpperCase();
                    const fileSize = kbToMb(files[0].size);

                    if (fileName.length > 12) {
                        fileName = fileName.substring(0, 10) + '..' + '.' + fileExtension;
                    }

                    previewFileNames[i].textContent = fileName;
                    previewFileNames[i].setAttribute('data-fileName', fullFileName);
                    previewFileSizes[i].textContent = fileSize;

                    uploadedFiles.push(files[0]);

                    if (individualUploadItems.length === uploadedFiles.length) {
                        individualUploadSubmit.removeAttribute('disabled');
                    }

                    return fileName;
                }

                return '';
            } else {
                const target = event.target as HTMLInputElement;

                if (validateFile(target.files[0]) === true) {
                    const filePath = target.files[0].name;
                    let fileName = filePath.split('\\').pop().split('/').pop();
                    let fullFileName = filePath.split('\\').pop().split('/').pop();
                    const fileExtension = fileName.split('.').pop().toUpperCase();
                    const fileSize = kbToMb(target.files[0].size);

                    if (fileName.length > 12) {
                        fileName = fileName.substring(0, 10) + '..' + '.' + fileExtension;
                    }

                    if (previewFileNames[i] && previewFileSizes[i]) {
                        previewFileNames[i].textContent = fileName;
                        previewFileSizes[i].textContent = fileSize;
                        previewFileNames[i].setAttribute('data-fileName', fullFileName);

                        uploadedFiles.push(target.files[0]);

                        if (individualUploadItems.length === uploadedFiles.length) {
                            individualUploadSubmit.removeAttribute('disabled');
                        }
                    } else {
                        console.error(`Element at index ${i} not found.`);
                    }

                    if (individualUploadItems.length === uploadedFiles.length) {
                        individualUploadSubmit.removeAttribute('disabled');
                    }

                    return fileName;
                } else {
                    return '';
                }
            }
        };

        const renderPreview = (event: Event, index: number, droppedFile = null) => {
            if (droppedFile) {
                if (validateFile(droppedFile) === true) {
                    const reader = new FileReader();
                    reader.onload = function (e: ProgressEvent<FileReader>) {
                        const fileType = droppedFile.type;
                        const data = e.target?.result;
                        const previewContainer = previewContainers[index];
                        const individualUploadMain = individualUploadMains[index];
                        const previewImage = previewImages[index];
                        const previewPdf = previewPdfs[index];

                        if (previewContainer) {
                            if (fileType.startsWith('image/')) {
                                showImgPreview(data, index, previewContainer, individualUploadMain);
                            } else if (fileType.startsWith('application/pdf')) {
                                showPdfPreview(previewContainer, individualUploadMain, previewPdf, previewImage);
                            }
                        }
                    };
                    reader.readAsDataURL(droppedFile);
                }
            } else {
                const target = event.target as HTMLInputElement;
                const file: File | null = target.files ? target.files[0] : null;

                if (file && validateFile(file) === true) {
                    const reader = new FileReader();
                    reader.onload = function (e: ProgressEvent<FileReader>) {
                        const fileType = file.type;
                        const data = e.target?.result;
                        const previewContainer = previewContainers[index];
                        const individualUploadMain = individualUploadMains[index];
                        const previewImage = previewImages[index];
                        const previewPdf = previewPdfs[index];

                        if (previewContainer) {
                            if (fileType.startsWith('image/')) {
                                showImgPreview(data, index, previewContainer, individualUploadMain);
                            } else if (fileType.startsWith('application/pdf')) {
                                showPdfPreview(previewContainer, individualUploadMain, previewPdf, previewImage);
                            }
                        }
                    };
                    reader.readAsDataURL(file);
                }
            }
        };

        const showPdfPreview = (preview: Element, main: Element, previewPdf: Element, previewImage: Element) => {
            previewPdf.classList.add('individual-upload__preview-pdf--active');
            previewImage.classList.remove('individual-upload__preview-image--active');
            main.classList.remove('individual-upload__upload-main--active');
            preview.classList.add('individual-upload__preview--active');
        };

        const showImgPreview = (data, i: number, preview: Element, main: Element) => {
            main.classList.remove('individual-upload__upload-main--active');
            preview.classList.add('individual-upload__preview--active');
            previewImages[i].src = data as string;
            previewImages[i].classList.add('individual-upload__preview-image--active');
            previewPdfs[i].classList.remove('individual-upload__preview-pdf--active');
        };

        const handleProgress = (fileInput: HTMLInputElement, index: number) => {
            const progressBars = individualUploadActive.querySelectorAll('.individual-upload__progress-inner');
            const progress = progressBars[index] as HTMLDivElement;

            const file = fileInput.files[0] || null;

            if (file && file.size) {
                const fileSize = file.size;
                let loaded = 0;

                const reader = new FileReader();

                reader.onprogress = function (event) {
                    if (event.lengthComputable) {
                        loaded = event.loaded;
                        const percent = (loaded / fileSize) * 100;
                        progress.style.width = percent + '%';

                        if (percent === 100) {
                            progress.classList.add('individual-upload__progress-inner--complete');
                            invalidUploadStatuses[index].classList.remove('individual-upload__status-invalid--active');
                            validUploadStatuses[index].classList.add('individual-upload__status-valid--active');
                        }
                    }
                };

                reader.readAsDataURL(file);

                return reader;
            } else {
                const percent = 100;
                progress.style.width = percent + '%';
                progress.classList.add('individual-upload__progress-inner--complete');
                invalidUploadStatuses[index].classList.remove('individual-upload__status-invalid--active');
                validUploadStatuses[index].classList.add('individual-upload__status-valid--active');

                return percent;
            }
        };

        const handleDragOver = (e: Event, area: HTMLDivElement) => {
            e.preventDefault();

            area.classList.add('individual-upload__upload--active');
        };

        const handleFileDrop = (e: Event, index: number, area: HTMLDivElement) => {
            e.preventDefault();
            const button = individualCaptureButtons[index] as HTMLInputElement;

            handleFile(e, index, true);
            handleProgress(button, index);
            area.classList.remove('individual-upload__upload--active');
        };

        const handleDragLeave = (e: Event, area: HTMLDivElement) => {
            e.preventDefault();

            area.classList.remove('individual-upload__upload--active');
        };

        const resetIndividualUpload = () => {
            const individualUploadWrapper: HTMLDivElement = document.querySelector(
                '.individual-upload__wrapper--active',
            );

            individualUploadWrapper?.classList.remove('individual-upload__wrapper--active');

            // reset individualAuth height
            individualAuth.style.height = 'auto';
        };

        individualUploadBackButtons.forEach((button: HTMLButtonElement) => {
            button.addEventListener('click', () => {
                resetIndividualUpload();
            });
        });

        individualUploadButtons.forEach((button: HTMLInputElement, i: number) => {
            button.addEventListener('change', (e) => {
                handleFile(e, i);
                renderPreview(e, i);
                handleProgress(button, i);
            });
        });

        individualCaptureButtons.forEach((button: HTMLInputElement, i: number) => {
            button.addEventListener('change', (e: Event) => {
                handleFile(e, i);

                const target = e.target as HTMLInputElement;

                if (validateFile(target.files[0]) === true) {
                    handleProgress(button, i);
                    renderPreview(e, i);
                }
            });
        });

        individualUploadAreas.forEach((area: HTMLDivElement, i: number) => {
            area.addEventListener('dragover', (e) => {
                handleDragOver(e, area);
            });

            area.addEventListener('dragleave', (e) => {
                handleDragLeave(e, area);
            });

            area.addEventListener('drop', (e) => {
                const file = e.dataTransfer.files[0];

                handleFileDrop(e, i, area);
                renderPreview(e, i, file);
            });
        });

        individualUploadPreviewDeleteButton.forEach((button: HTMLButtonElement, i: number) => {
            button.addEventListener('click', () => {
                const fileName = button.parentElement.previousElementSibling.children[0].getAttribute('data-fileName');
                const filteredArray = uploadedFiles.filter((obj) => obj.name !== fileName);

                uploadedFiles = filteredArray;

                previewContainers[i].classList.remove('individual-upload__preview--active');
                individualUploadMains[i].classList.add('individual-upload__upload-main--active');
                individualUploadButtons[i].value = '';
                individualUploadSubmit.setAttribute('disabled', 'true');
            });
        });
    }
};

export default renderUpload;
