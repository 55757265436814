import setMaxDateValue from '../../../helpers/dateMaxValue';
import { HTMLInputElementWithPicker } from "./individualProfile.types";

const individualProfileForm = document.querySelector(".individual-profile__form") as HTMLFormElement;

if (individualProfileForm) {
  const firstNameInput = document.querySelector(".individual-profile__first-name") as HTMLInputElement;
  const middleNameInput = document.querySelector(".individual-profile__middle-name") as HTMLInputElement;
  const lastNameInput = document.querySelector(".individual-profile__last-name") as HTMLInputElement;
  const dateInput = document.querySelector(".individual-profile__dob") as HTMLInputElementWithPicker;
  const dateInputOverlay = document.querySelector(".individual-profile__dob-overlay") as HTMLDivElement;
  const dateInputIcon = document.querySelector(".individual-profile__dob-icon-container") as HTMLDivElement;
  const countryInput = document.querySelector(".individual-profile__country") as HTMLSelectElement;

  const firstNameValidation = document.querySelector(".individual-profile__validation-first-name") as HTMLSpanElement;
  const middleNameValidation = document.querySelector(".individual-profile__validation-middle-name") as HTMLSpanElement;
  const lastNameValidation = document.querySelector(".individual-profile__validation-last-name") as HTMLSpanElement;
  const dateValidation = document.querySelector(".individual-profile__validation-dob") as HTMLSpanElement;
  const countryValidation = document.querySelector(".individual-profile__validation-country") as HTMLSpanElement;

  let isFormValid = false

  const resetIndividualProfileValidation = () => {
    firstNameValidation.classList.remove("individual-profile__validation--active")
    middleNameValidation.classList.remove("individual-profile__validation--active")
    lastNameValidation.classList.remove("individual-profile__validation--active")
    dateValidation.classList.remove("individual-profile__validation--active")
    countryValidation.classList.remove("individual-profile__validation--active")
  }

  const resetForm = () => {
    firstNameInput.value = ""
    lastNameInput.value = ""
    middleNameInput.value = ""
    dateInput.value = ""
    countryInput.value = ""

    countryInput.classList.remove("individual-profile__country--active")
    dateInput.classList.remove("individual-profile__dob--active")
  }

  const nameValidation = (name: string) => {
    const regex = /^[\u0600-\u06FFa-zA-Z\s'-]+$/;
    return regex.test(name);
};

  const validateForm = () => {
    isFormValid = true;

    if (!firstNameInput.value || !nameValidation(firstNameInput.value)) {
      firstNameValidation.classList.add("individual-profile__validation--active");
      firstNameValidation.textContent = "First name is required and must contain only letters";
      isFormValid = false;
    }
  
    if (middleNameInput.value && !nameValidation(middleNameInput.value)) {
      middleNameValidation.classList.add("individual-profile__validation--active");
      middleNameValidation.textContent = "Middle name must contain only letters";
      isFormValid = false;
    } 
  
    if (!lastNameInput.value || !nameValidation(lastNameInput.value)) {
      lastNameValidation.classList.add("individual-profile__validation--active");
      lastNameValidation.textContent = "Last name is required and must contain only letters";
      isFormValid = false;
    }
  
    if (!dateInput.value) {
      dateValidation.classList.add("individual-profile__validation--active");
      isFormValid = false;
    }
  
    if (!countryInput.value) {
      countryValidation.classList.add("individual-profile__validation--active");
      isFormValid = false;
    }
  
    return isFormValid;
  };

  const handleDateClick = () => {
    try {
      dateInput.showPicker()
    } catch (error) {
      console.log(error.message);
    }
  }

  const listenForInputChanges = () => {
    firstNameInput.addEventListener("input", () => {
      firstNameValidation.classList.remove("individual-profile__validation--active")
    })

    lastNameInput.addEventListener("input", () => {
      lastNameValidation.classList.remove("individual-profile__validation--active")
    })

    dateInput.addEventListener("click", () => {
      handleDateClick()
    })

    dateInputIcon.addEventListener("click", () => {
      handleDateClick()
    })

    dateInput.addEventListener("input", () => {
      dateInput.classList.add("individual-profile__dob--active")
      dateValidation.classList.remove("individual-profile__validation--active")
      dateInputOverlay.classList.add("individual-profile__dob-overlay--hidden");
    })

    countryInput.addEventListener("input", () => {
      countryValidation.classList.remove("individual-profile__validation--active")
      countryInput.classList.add("individual-profile__country--active")
    })
  }

  listenForInputChanges()
  dateInput.setAttribute("max", setMaxDateValue());

  individualProfileForm.addEventListener("submit", async e => {
    resetIndividualProfileValidation()

    const formValues = {
      firstName: firstNameInput.value,
      middleName: middleNameInput.value,
      lastName: lastNameInput.value,
      dateOfBirth: dateInput.value,
      countryOfBirth: countryInput.value,
    };

    validateForm()

    if (isFormValid) {
      isFormValid = false
    }
    else {
      e.preventDefault();
    }
  });
}