import Glide from '@glidejs/glide';

const mobileMenuPlatform = document.querySelector('.menu-mobile-platform__container') as HTMLElement;

if (mobileMenuPlatform) {
    const mobileMenuOverlayPlatform = document.querySelector('.menu-mobile-platform__overlay') as HTMLElement;
    const mobileMenuIconPlatform = document.querySelector('.menu-mobile-platform__icon') as HTMLElement;
    const mobileMenuIconContainerPlatform = document.querySelector(
        '.menu-mobile-platform__icon-container',
    ) as HTMLElement;
    const mobileMenuButtonPlatform = document.querySelector('.menu-mobile-platform__icon-container') as HTMLElement;
    const htmlPlatform = document.querySelector('html') as HTMLElement;
    const carousel = mobileMenuPlatform.querySelector('.menu-mobile-platform__carousel') as HTMLDivElement;
    const cards: NodeListOf<HTMLDivElement> = carousel.querySelectorAll('.dashboard-contact');
    const recipientDetailsOverlays: NodeListOf<HTMLDivElement> =
        mobileMenuPlatform.querySelectorAll('.recipient-detail__overlay');
    const closeButtons: NodeListOf<HTMLButtonElement> = mobileMenuPlatform.querySelectorAll('.recipient-detail__close');

    const handleOverlay = (card: Element, index: number, overlays) => {
        let activeIndex = index;

        card.addEventListener('click', () => {
            overlays[activeIndex].classList.add('recipient-detail__overlay--active');
        });

        card.addEventListener('keydown', (e) => {
            const keyboardEvent = <KeyboardEvent>e;

            if (keyboardEvent.key === 'Enter') {
                overlays[activeIndex].classList.add('recipient-detail__overlay--active');
            }
        });

        if (overlays[activeIndex]) {
            overlays[activeIndex].addEventListener('click', (e) => {
                const targetElement = e.target as HTMLElement;

                if (targetElement.classList.contains('recipient-detail__overlay')) {
                    overlays[activeIndex].classList.remove('recipient-detail__overlay--active');
                }
            });
        }
    };

    const handleMenuClose = () => {
        mobileMenuIconPlatform.classList.remove('menu-mobile-platform__icon--open');
        mobileMenuOverlayPlatform.classList.remove('menu-mobile-platform__overlay--active');
        htmlPlatform.style.overflow = 'auto';
    };

    const handleShowMenu = () => {
        mobileMenuIconPlatform.classList.add('menu-mobile-platform__icon--open');
        mobileMenuOverlayPlatform.classList.add('menu-mobile-platform__overlay--active');
        htmlPlatform.style.overflow = 'hidden';
    };

    mobileMenuOverlayPlatform.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            handleMenuClose();
        }
    });

    mobileMenuOverlayPlatform.addEventListener('click', (e) => {
        const targetElement = e.target as HTMLElement;

        if (targetElement.className === 'menu-mobile-platform__overlay menu-mobile-platform__overlay--active') {
            handleMenuClose();
        }
    });

    if (carousel) {
        var glide = new Glide(carousel, {
            type: 'slider',
            perView: 1.3,
            gap: 10,
            rewind: false,
            bound: false,
        });

        glide.mount();

        cards.forEach((card, index: number) => {
            handleOverlay(card, index, recipientDetailsOverlays);
        });

        closeButtons.forEach((button) => {
            button.addEventListener('click', () => {
                button.parentElement.parentElement.parentElement.parentElement.classList.remove(
                    'recipient-detail__overlay--active',
                );
            });
        });
    }

    if (mobileMenuIconContainerPlatform) {
        mobileMenuIconContainerPlatform.addEventListener('click', (e) => {
            if (mobileMenuIconPlatform.classList.contains('menu-mobile-platform__icon--open')) {
                handleMenuClose();
            } else {
                handleShowMenu();
            }
        });
    }

    if (mobileMenuButtonPlatform) {
        mobileMenuButtonPlatform.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                if (mobileMenuIconPlatform.classList.contains('menu-mobile-platform__icon--open')) {
                    handleMenuClose();
                } else {
                    handleShowMenu();
                }
            }
        });
    }
}
