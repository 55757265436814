const clickAndDrag = (selector: string, scroll_speed: number = 3, classOnEvent: string = 'dragging') => {
  const slider: HTMLElement = document.querySelector(selector);
  let isDown: boolean = false;
  let startX: number;
  let scrollLeft: number;

  slider.addEventListener('mousedown', (e: MouseEvent) => {
      // Do not trigger drag if mousedown outside slider
      if (e.clientX < slider.offsetLeft || e.clientX > slider.offsetLeft + slider.offsetWidth) {
          return; 
      }
      e.preventDefault();
      isDown = true;
      slider.classList.add(classOnEvent);
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;

      slider.addEventListener('click', (event) => {
          if (Math.abs(startX - event.pageX + slider.offsetLeft) > 3) {
              event.preventDefault();
          }
      });
  });

  slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove(classOnEvent);
  });

  slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove(classOnEvent);
  });

  slider.addEventListener('mousemove', (e: MouseEvent) => {
      if (!isDown) return;

      e.preventDefault();

      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * scroll_speed;      
      slider.scrollLeft = scrollLeft - walk;
  });
};

export default clickAndDrag;