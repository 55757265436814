const orgManualEntryForm = document.querySelector(".org-manual-entry__form") as HTMLFormElement;

if (orgManualEntryForm) {
  const nameInput = document.querySelector(".org-manual-entry__name") as HTMLInputElement;
  const numberInput = document.querySelector(".org-manual-entry__number") as HTMLInputElement;
  const address1Input = document.querySelector(".org-manual-entry__address-1") as HTMLInputElement;
  const address2Input = document.querySelector(".org-manual-entry__address-2") as HTMLInputElement;
  const address3Input = document.querySelector(".org-manual-entry__address-3") as HTMLInputElement;
  const postcodeInput = document.querySelector(".org-manual-entry__postcode") as HTMLInputElement;
  const countryInput = document.querySelector(".org-manual-entry__country") as HTMLSelectElement;

  const nameValidation = document.querySelector(".org-manual-entry__validation-name") as HTMLSpanElement;
  const numberValidation = document.querySelector(".org-manual-entry__validation-number") as HTMLSpanElement;
  const address1Validation = document.querySelector(".org-manual-entry__validation-address-1") as HTMLSpanElement;
  const postcodeValidation = document.querySelector(".org-manual-entry__validation-postcode") as HTMLSpanElement;
  const countryValidation = document.querySelector(".org-manual-entry__validation-country") as HTMLSpanElement;

  let isFormValid = false

  const resetValidation = () => {
    nameValidation.classList.remove("org-manual-entry__validation--active")
    numberValidation.classList.remove("org-manual-entry__validation--active")
    address1Validation.classList.remove("org-manual-entry__validation--active")
    postcodeValidation.classList.remove("org-manual-entry__validation--active")
    countryValidation.classList.remove("org-manual-entry__validation--active")
  }

  const resetForm = () => {
    nameInput.value = ""
    numberInput.value = ""
    address1Input.value = ""
    address2Input.value = ""
    address3Input.value = ""
    postcodeInput.value = ""
    countryInput.value = ""
  }

  const validateForm = () => {
    if(!nameInput.value) {      
      nameValidation.classList.add("org-manual-entry__validation--active")
    }

    if(!numberInput.value) {      
      numberValidation.classList.add("org-manual-entry__validation--active")
    }

    if(!address1Input.value) {      
      address1Validation.classList.add("org-manual-entry__validation--active")
    }

    if(!postcodeInput.value) {      
      postcodeValidation.classList.add("org-manual-entry__validation--active")
    }

    if(!countryInput.value) {      
      countryValidation.classList.add("org-manual-entry__validation--active")
    }

    if(nameInput.value && numberInput.value && address1Input.value && postcodeInput.value && countryInput.value){
      isFormValid = true 
    } 
  }

  const listenForInputChanges = () => {
    nameInput.addEventListener("input", () => {
      nameValidation.classList.remove("org-manual-entry__validation--active")
    })
  
    numberInput.addEventListener("input", () => {
      numberValidation.classList.remove("org-manual-entry__validation--active")
    })

    address1Input.addEventListener("input", () => {
      address1Validation.classList.remove("org-manual-entry__validation--active")
    })
    
    postcodeInput.addEventListener("input", () => {
      postcodeValidation.classList.remove("org-manual-entry__validation--active")
    })
  
    countryInput.addEventListener("input", () => {
      countryValidation.classList.remove("org-manual-entry__validation--active")
    })
  }

  listenForInputChanges()

  orgManualEntryForm.addEventListener("submit", async e => {    
    resetValidation()
    
    const formValues = {
      name: nameInput.value,
      number: numberInput.value,
      address1: address1Input.value,
      address2: address2Input.value,
      address3: address3Input.value,
      postcode: postcodeInput.value,
      country: countryInput.value,
    };
    
    validateForm()
    
    if (isFormValid) {
      resetValidation()
      isFormValid = false
    } 
    else {
      e.preventDefault();
    }
  });
}