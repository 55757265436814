import Glide from '@glidejs/glide';

const teamGridCarousels: NodeListOf<HTMLElement> = document.querySelectorAll<HTMLElement>(".team-grid__carousel-inner");
let teamGridComponents: NodeListOf<HTMLElement>;

if (window.innerWidth > 480) {
  teamGridComponents = document.querySelectorAll<HTMLElement>(".team-grid__grid");
} else {
  teamGridComponents = document.querySelectorAll<HTMLElement>(".team-grid__carousel");
}

if (teamGridCarousels.length > 0) {
  const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
    const slidesLength = arr.length;
    const rangeMax = (slidesLength - 2).toString();
  
    input.max = rangeMax;
  
    setRangeThumbWidth(rangeMax, input);
  };
  
  const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
    const width = 200 / +max;
    input.style.setProperty('--thumb-size', width.toString() + "px");
  
    if (max === "1") {
      input.style.setProperty('--thumb-size', "100px");
    }
  };
  
  teamGridCarousels.forEach((item) => {
    const glide = new Glide(item, {
      type: 'slider',
      perView: 1.7,
      gap: 10,
      rewind: true,
      bound: true,
      breakpoints: {
        480: {
          perView: 2.065,
        },
      }
    });

    glide.mount();

    const carouselRange = item.querySelector(".team-grid__carousel-range") as HTMLInputElement;
    const slides: NodeListOf<Element> = item.querySelectorAll(".team-grid__card");

    carouselRange.addEventListener("input", e => {
      setTimeout(() => {
        glide.go(`=${carouselRange.value}`);
      }, 500);
    });

    glide.on('move.after', () => {
      const currentIndex = glide.index;
      carouselRange.value = currentIndex.toString();
    });

    setRangeMax(slides, carouselRange);
  });
}

if (teamGridComponents.length > 0) {
  teamGridComponents.forEach(item => {

    const teamGridCards: NodeListOf<Element> = item.querySelectorAll(".team-grid__card");
    const teamGridModals: NodeListOf<Element> = item.querySelectorAll(".team-grid__modal");

    teamGridCards.forEach((card, i) => {

      card.addEventListener("click", () => {
        modalIndex = i;
        const modal = teamGridModals[modalIndex] as HTMLDialogElement;
  
        modal.showModal();
  
        setActiveModal(modal);
        attachModalEventListeners(); 
      });

        let activeModal: HTMLDialogElement | null = null;
        let modalIndex = 0;

        const handlePrev = (modals: NodeListOf<Element>, index: number) => {
          closeAllModals()

          if (index === 0) {
            modalIndex = modals.length - 1;
          } else {
            modalIndex--;
          }

          const newModal = modals[modalIndex] as HTMLDialogElement;

          setActiveModal(newModal);
          newModal?.showModal();
        };

        const handleNext = (modals: NodeListOf<Element>, index: number) => {
          closeAllModals()

          if (index === modals.length - 1) {
            modalIndex = 0;
          } else {
            modalIndex++;
          }

          const newModal = modals[modalIndex] as HTMLDialogElement;

          setActiveModal(newModal);
          newModal?.showModal();
        };

        const attachModalEventListeners = () => {
          const modalPrevButton = activeModal?.querySelector(".team-grid__modal-prev") as HTMLButtonElement | null;
          const modalNextButton = activeModal?.querySelector(".team-grid__modal-next") as HTMLButtonElement | null;
          const modalCloseButton = activeModal?.querySelector(".team-grid__modal-close") as HTMLButtonElement;

          modalCloseButton.blur();
          activeModal.focus()

          modalCloseButton.addEventListener("click", () => activeModal?.close());

          activeModal.addEventListener("click", e => {
            const target = e.target as Element;

            if(target.classList.contains("team-grid__modal")){
              activeModal.close()
            }
          })

          modalPrevButton?.removeEventListener("click", handlePrevEvent);
          modalNextButton?.removeEventListener("click", handleNextEvent);

          modalPrevButton?.addEventListener("click", handlePrevEvent);
          modalNextButton?.addEventListener("click", handleNextEvent);
        };

        const handlePrevEvent: EventListener = () => {
          handlePrev(teamGridModals, modalIndex);

          const prevButton = activeModal?.querySelector(".team-grid__modal-prev") as HTMLButtonElement | null;

          prevButton.focus()
        };

        const handleNextEvent: EventListener = () => {
          handleNext(teamGridModals, modalIndex);

          const nextButton = activeModal?.querySelector(".team-grid__modal-next") as HTMLButtonElement | null;

          nextButton.focus()
        };

        const onActiveModalChange = () => {
          attachModalEventListeners();
        };

        const setActiveModal = (modal: HTMLDialogElement | null) => {
          activeModal = modal;
          onActiveModalChange();
        };

        const closeAllModals = () => {
          teamGridModals.forEach((modal: HTMLDialogElement) => {
            modal.close()
          })
        }
    });
  })
}