const sendFundsOverlay = document.querySelector(".send-funds__overlay") as HTMLElement

if(sendFundsOverlay){
  const sendFundsCards: NodeListOf<Element> = document.querySelectorAll(".send-funds__card");
  const sendFundsNested: NodeListOf<Element> = document.querySelectorAll(".send-funds__nested");
  const sendFundsMain = document.querySelector(".send-funds__main") as HTMLElement
  const sendFundsBack = document.querySelector(".send-funds__back") as HTMLElement
  const sendFundsCloseButton = document.querySelector(".send-funds__close") as HTMLElement
  const sendFundsCloseButtonMobile = document.querySelector(".send-funds__close-mobile") as HTMLElement
  const sendFundsShareButton = document.querySelector(".send-funds__share") as HTMLElement
  const sendFundsBackButton = document.querySelector(".send-funds__back") as HTMLElement
  const sendFundsButtons = document.querySelector(".send-funds__buttons") as HTMLElement
  const mobileMenu = document.querySelector(".menu-mobile__overlay") as HTMLElement
  const menuMobileIcon = document.querySelector(".menu-mobile__icon") as HTMLElement
  const htmlEl: HTMLElement | null = document.documentElement;

  const closeSendFunds = () => {
    sendFundsOverlay.classList.remove("send-funds__overlay--active")
    htmlEl.style.overflow="auto"
    
    setTimeout(() => {
      sendFundsNested.forEach(item  => {
        item.classList.remove("send-funds__nested--active")
      })
  
      sendFundsMain.classList.remove("send-funds__main--hidden")
      sendFundsButtons.classList.remove("send-funds__buttons--border")
      sendFundsBackButton.classList.remove("send-funds__back--active")
      sendFundsShareButton.classList.remove("send-funds__share--active")
    }, 500)
  }

  const openSendFunds = (index: number) => {
    if(mobileMenu?.classList.contains("menu-mobile__overlay--active")){
      mobileMenu.classList.remove("menu-mobile__overlay--active")
      menuMobileIcon.classList.remove("menu-mobile__icon--open")
    }

    sendFundsMain.classList.add("send-funds__main--hidden")
    sendFundsNested[index].classList.add("send-funds__nested--active")
    sendFundsButtons.classList.add("send-funds__buttons--border")
    sendFundsBackButton.classList.add("send-funds__back--active")
    sendFundsShareButton.classList.add("send-funds__share--active")
  }

  const goBackFunds = () => {
    sendFundsNested.forEach(item  => {
      item.classList.remove("send-funds__nested--active")
    })

    sendFundsMain.classList.remove("send-funds__main--hidden")
    sendFundsButtons.classList.remove("send-funds__buttons--border")
    sendFundsBackButton.classList.remove("send-funds__back--active")
    sendFundsShareButton.classList.remove("send-funds__share--active")
  }

  if(sendFundsCards.length > 0){
    sendFundsCards.forEach((card, i) => {
      card.addEventListener("click", () => openSendFunds(i))
    });

    sendFundsCards.forEach((card, i) => {
      card.addEventListener("keydown", e => {
        const keyboardEvent = <KeyboardEvent> e;

        if(keyboardEvent.key === "Enter"){
          openSendFunds(i)
        }
      })
    });
  }

  if(sendFundsBack) {
    sendFundsBack.addEventListener("click", () => goBackFunds())
  }

  if(sendFundsCloseButton){
    sendFundsCloseButton.addEventListener("click", closeSendFunds)
  }

  if(sendFundsCloseButtonMobile){
    sendFundsCloseButtonMobile.addEventListener("click", closeSendFunds)
  }

  if(sendFundsOverlay){
    sendFundsOverlay.addEventListener("keydown", e => {
      if(e.key === "Escape"){
        closeSendFunds()
      }
    })

    sendFundsOverlay.addEventListener("click", e => {
      const targetElement = e.target as HTMLElement;

      if(targetElement.classList.contains("send-funds__overlay--active")){
        closeSendFunds();
      }
    });
  }
}