 /* eslint-disable */

import axios from "axios";
import { UpdateCountryPayload, UpdateCountryResponse, UpdateCurrencyPayload, UpdateCurrencyResponse, UpdatePasswordPayload, UpdatePasswordResponse } from "./myAccount.types";

const account = document.querySelector(".account__wrapper") as HTMLDivElement;

if (account) {
  let type = null;
  let conditions = []
  let validations = [false]

  const validationMessages = [
    "Must be at least 6 characters long. ",
    "Must have at least one lowercase letter. ",
    "Must have at least one uppercase letter. ",
    "Must include a number. ",
    "Must include any of the following symbols: !@#$&*."
  ]

  const changeButtons: NodeListOf<HTMLButtonElement> = account.querySelectorAll(".account__button");
  const closeButtons: NodeListOf<HTMLButtonElement> = account.querySelectorAll(".account-slider__close");
  const overlays: NodeListOf<HTMLDivElement> = account.querySelectorAll(".account-slider__overlay");

  const passwordInputs: NodeListOf<HTMLInputElement> = account.querySelectorAll(".account-slider__password");
  const passwordOldInput = document.querySelector('.account-slider__password-old') as HTMLInputElement;
  const passwordOldValidation = document.querySelector(
    '.account-slider__validation-password-old',
  ) as HTMLSpanElement;
  const passwordNewInput = document.querySelector('.account-slider__password-new') as HTMLInputElement;
  const passwordNewValidation = document.querySelector(
    '.account-slider__validation-password-new',
  ) as HTMLSpanElement;
  const passwordAgainInput = document.querySelector('.account-slider__password-again') as HTMLInputElement;
  const passwordAgainValidation = document.querySelector(
    '.account-slider__validation-password-again',
  ) as HTMLSpanElement;
  const photoValidation = document.querySelector(
    '.account-slider__validation-photo',
  ) as HTMLSpanElement;
  const showPasswordButtons = account.querySelectorAll('.zmdi-eye') as NodeListOf<Element>;
  const hidePasswordButtons = account.querySelectorAll('.zmdi-eye-off') as NodeListOf<Element>;
  const countryDropdown = document.querySelector('#account-slider__country') as HTMLSelectElement;
  const countryOptions = countryDropdown?.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
  const currencyDropdown = document.querySelector('.account-slider__currency') as HTMLSelectElement;
  const currencyOptions = currencyDropdown?.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
  const uploadInput = document.querySelector('.account__upload-input') as HTMLInputElement;

  const forms: NodeListOf<HTMLFormElement> = account.querySelectorAll(".account-slider__form");
  const passwordForm = account.querySelector(".account-slider__password-form") as HTMLFormElement;
  const regexPattern = passwordForm.getAttribute("pattern") as string || null;
  const notification = account.querySelector(".account__notify") as HTMLParagraphElement;
  const submitButtons = account.querySelectorAll(".button__label") as NodeListOf<HTMLInputElement>;
  const accountImages = document.querySelectorAll(".account__image") as NodeListOf<HTMLImageElement>;

  //API Variables & components
  let isLoading = false;
  const successElm = account.querySelector(".account__notify--success") as HTMLParagraphElement;
  const failElm = account.querySelector(".account__notify--fail") as HTMLParagraphElement;
  const currentCountryElm = account.querySelector("#currentResidenceCountry") as HTMLParagraphElement;
  const currentCurrencyElm = account.querySelector("#currentCurrency") as HTMLParagraphElement;

  const closeOverlays = () => {
    overlays.forEach(item => item.classList.remove("account-slider__overlay--active"))
    setTimeout(() => {
      clearForm()
      resetValidations()
    }, 500);
  }

  const resetValidations = () => {
    passwordOldValidation.classList.remove("account-slider__validation--active")
    passwordNewValidation.classList.remove("account-slider__validation--active")
    passwordAgainValidation.classList.remove("account-slider__validation--active")
    photoValidation.classList.remove("account-slider__validation--active")
  }

  const validatePasswords = () => {
    if (!passwordOldInput.value) {
      passwordOldValidation.classList.add("account-slider__validation--active")
      passwordOldValidation.textContent = "This field cannot be empty"

      return false
    }

    if (!passwordNewInput.value) {
      passwordNewValidation.classList.add("account-slider__validation--active")
      passwordNewValidation.textContent = "This field cannot be empty"

      return false
    }

    if (!passwordAgainInput.value) {
      passwordAgainValidation.classList.add("account-slider__validation--active")
      passwordAgainValidation.textContent = "This field cannot be empty"

      return false
    }

    if (passwordNewInput.value !== passwordAgainInput.value) {
      passwordNewValidation.classList.add("account-slider__validation--active")
      passwordNewValidation.textContent = "The passwords do not match."
      passwordAgainValidation.classList.add("account-slider__validation--active")
      passwordAgainValidation.textContent = "The passwords do not match."

      return false
    }

    return true
  }

  const showPhotoValidations = () => {
    photoValidation.classList.add("account-slider__validation--active")
  }

  const validatePhoto = (file: File) => {
    if (file && file.size <= 5000000) {
      return true
    }

    showPhotoValidations()

    return false
  }

  const clearForm = () => {
    passwordInputs.forEach(input => input.value = "")
  }

  const updateButtons = () => {
    submitButtons.forEach(button => button.disabled = isLoading)
  }

  const updateMessages = (success?: boolean) => {
    if (success) {
      failElm.classList.remove("active");
      successElm.classList.add("active");
    } else if (success === false) {
      failElm.classList.add("active");
      successElm.classList.remove("active");
    } else if (isLoading) {
      failElm.classList.remove("active");
      successElm.classList.remove("active");
    }
  }

  const updateUI = (success?: boolean) => {
    updateButtons();
    updateMessages(success);

    if (success) {
      clearForm();
      closeOverlays();
    }
    else if (success === false) {
      closeOverlays();
    }
  }

  const enableOptions = (options: NodeListOf<HTMLOptionElement>, currentOptionIdx: number) => {
    options.forEach(option => {
      option.disabled = false
      option.style.color = "#000";
    });

    options[currentOptionIdx].disabled = true;
    options[currentOptionIdx].style.color = "rgba(0, 0, 0, 0.5)";
  }

  const updateCurrency = async () => {
    isLoading = true;
    updateUI();
    let payload: UpdateCurrencyPayload = {
      currencyName: currencyDropdown.value
    }

    try {
      //For development:
      // const response = await axios.post<UpdateCurrencyResponse>("https://localhost:44323/api/member/updatePreferredCurrency", payload);
      
      const response = await axios.post<UpdateCurrencyResponse>("/api/member/updatePreferredCurrency", payload);
      const { data } = response;
      
      isLoading = false;
      updateUI(data.success);
      
      if (data.success) {
        enableOptions(currencyOptions, currencyDropdown.selectedIndex);
        currentCurrencyElm.textContent = currencyDropdown.options[currencyDropdown.selectedIndex].text;
      }
    } catch (exception: any) {
      updateUI(false);
      console.log(exception);
    }
  }

  const updateCountry = async () => {
    isLoading = true;
    updateUI();
    let payload: UpdateCountryPayload = {
      country: countryDropdown.value
    }

    try {
      //For development:
      // const response = await axios.post<UpdateCountryResponse>("https://localhost:44323/api/member/updateResidenceCountry", payload);
      
      const response = await axios.post<UpdateCountryResponse>("/api/member/updateResidenceCountry", payload);
      const { data } = response;
      
      isLoading = false;
      updateUI(data.success);

      if (data.success) {
        enableOptions(countryOptions, countryDropdown.selectedIndex);
        currentCountryElm.textContent = countryDropdown.options[countryDropdown.selectedIndex].text;
      }
    } catch (exception: any) {
      updateUI(false);
      console.log(exception);
    }
  }

  const updatePassword = async () => {
    isLoading = true;
    updateUI();
    let payload: UpdatePasswordPayload = {
      currentPassword: passwordOldInput.value,
      newPassword: passwordNewInput.value
    }

    try {
      //For development:
      // const response = await axios.post<UpdatePasswordResponse>("https://localhost:44323/api/member/updatePassword", payload);
      
      const response = await axios.post<UpdatePasswordResponse>("/api/member/updatePassword", payload);
      const { data } = response;
      
      isLoading = false;
      if (!data.success) {
        passwordOldValidation.classList.add("account-slider__validation--active");
        passwordOldValidation.textContent = data.message;
        updateUI();
      } else {
        updateUI(true);
      }
    } catch (exception: any) {
      updateUI(false);
      console.log(exception);
    }
  }

  const updateProfilePicture = async (file: File) => {
    isLoading = true;
    updateUI();
    let payload = new FormData();
    payload.append("profilePicture", file);

    try {
      //For development:
      // const response = await axios.post<UpdatePasswordResponse>("https://localhost:44323/api/member/updateProfilePicture", payload);
      
      const response = await axios.post<UpdatePasswordResponse>("/api/member/updateProfilePicture", payload);
      const { data } = response;
      
      isLoading = false;
      updateUI(data.success);

      if (data.success) {
        accountImages.forEach(img => {
          img.src = URL.createObjectURL(file)
          img.classList.remove("navbar-platform__avatar--default")
          img.classList.remove("account__image--default")
        });
      }
    } catch (exception: any) {
      updateUI(false);
      console.log(exception);
    }
  }

  const handlePasswordSubmit = (e: Event) => {
    e.preventDefault();

    resetValidations()

    if (validatePasswords() && validateRegexPassword(passwordNewInput.value)) {
      updatePassword();
    }
  }

  const handleCountrySubmit = (e: Event) => {
    e.preventDefault();

    updateCountry();
  }

  const handleCurrencySubmit = (e: Event) => {
    e.preventDefault();

    updateCurrency();
  }

  const handlePhotoSubmit = (e: Event, file: File) => {
    e.preventDefault();
    resetValidations()

    if (validatePhoto(file)) {
      updateProfilePicture(file);
    }
  }

  const validateRegexPassword = (password: string) => {
    if (password && password?.length > 5) {
      validations[0] = true;
    }

    conditions.forEach((cond, index) => {
      if (new RegExp(cond).test(password)) {
        validations[index + 1] = true
      }
    })

    let isPasswordValid = false;

    if (validations.every((val) => val === true)) {
      isPasswordValid = true;
    } else {
      isPasswordValid = false
      showRegexPasswordValidationMessage();
    }

    return isPasswordValid;
  };

  const showRegexPasswordValidationMessage = () => {
    const validationMessage = validations
      .map((isValid, index) => !isValid ? validationMessages[index] : "")
      .filter(msg => msg)
      .join(" ");

    if (validations.every((val) => val === true)) {
      return
    } else {
      passwordNewValidation.classList.add("account-slider__validation--active");
      passwordNewValidation.textContent = validationMessage;

      return
    }
  }

  // extract individual conditions from the regex pattern
  const extractConditions = (regexPattern: string) => {
    conditions = regexPattern.match(/\(\?=(.*?)\)|\(\?!(.*?)\)|\(\?<=(.*?)\)|\(\?<(.*?)\)/g);

    conditions = conditions.filter(condition => condition !== null);

    // map each condition to a validation array
    conditions.map(condition => validations.push(false));
  };

  const hideNotification = () => {
    const time = notification.getAttribute("data-timeout") as string;

    if (time) {
      setTimeout(() => {
        notification.classList.remove("account__notify--fail")
        notification.classList.remove("account__notify--success")
      }, +time);
    }
  }

  uploadInput.addEventListener('change', (e: Event) => {
    const target = e.target as HTMLInputElement;
    const file = target.files[0] as File;

    handlePhotoSubmit(e, file)
  });

  overlays.forEach(item => {
    item.addEventListener("click", (e: Event) => {
      const target = e.target as HTMLDivElement;

      if (target.classList.contains("account-slider__overlay--active"))
        closeOverlays()
    })
  })

  closeButtons.forEach(button => {
    button.addEventListener("click", () => {
      closeOverlays()
    })
  })

  changeButtons.forEach((button, index: number) => {
    button.addEventListener("click", () => {
      button.nextElementSibling.classList.add("account-slider__overlay--active");

      if (button.classList.contains("account__button-password")) {
        type = "password";
      } else if (button.classList.contains("account__button-country")) {
        type = "country";
      } else if (button.classList.contains("account__button-currency")) {
        type = "currency";
      }
    })
  })

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeOverlays()
    }
  });

  showPasswordButtons.forEach((button) => {
    button.addEventListener('click', () => {
      button.previousElementSibling.setAttribute('type', 'text');
      button.classList.remove('zmdi-eye--active');
      button.nextElementSibling.classList.add('zmdi-eye-off--active');
    });
  });

  hidePasswordButtons.forEach((button) => {
    button.addEventListener('click', () => {
      button.previousElementSibling.previousElementSibling.setAttribute('type', 'password');
      button.classList.remove('zmdi-eye-off--active');
      button.previousElementSibling.classList.add('zmdi-eye--active');
    });
  });

  passwordOldInput.addEventListener("input", () => {
    resetValidations()
  })

  passwordNewInput.addEventListener("input", () => {
    resetValidations()
  })

  passwordAgainInput.addEventListener("input", () => {
    resetValidations()
  })

  forms.forEach(form => {
    form.addEventListener("submit", (e) => {
      switch (type) {
        case "password":
          handlePasswordSubmit(e)
          break;
        case "country":
          handleCountrySubmit(e)
          break;
        case "currency":
          handleCurrencySubmit(e)
          break;
        default:
          null
      }
    })
  })

  if (regexPattern) {
    extractConditions(regexPattern)
  }

  if (notification.classList.contains("account__notify--fail") || notification.classList.contains("account__notify--success")) {
    hideNotification()
  }
}