import { FetchNewsListingPayload, FetchNewsListingResponse, NewsCard } from './newsListing.types';
import axios from 'axios';

const newsListing = (document.querySelector('.news-listing') as HTMLDivElement) || null;

if (newsListing) {
    const nextButton = document.querySelector('.news-listing__button--next') as HTMLButtonElement;
    const prevButton = document.querySelector('.news-listing__button--prev') as HTMLButtonElement;
    const pageId = +(document.querySelector('#pageId') as HTMLInputElement).value;

    let newsCards: NewsCard[] = [];
    let pageNumber = 1;
    let pagesCount = 0;
    let isLoading = false;

    const fetchNews = async () => {
        const payload: FetchNewsListingPayload = {
            pageNumber,
            pageId,
        };

        isLoading = true;
        updateUI();

        try {
            // For developement:
            // const response = await axios.get<FetchNewsListingResponse>('https://localhost:44323/api/newsListing/getNews', {
            //     params: payload,
            //     headers: {
            //         'Access-Control-Allow-Origin': '*'
            //     }
            // });

            const response = await axios.get<FetchNewsListingResponse>('/api/newsListing/getNews', {
                params: payload,
            });

            newsCards = response.data.newsCards;
            pagesCount = response.data.pagesCount;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading = false;
            updateUI();
        }
    };

    const updateCards = () => {
        const grid = document.querySelector('.news-listing__grid') as HTMLDivElement;
        grid.innerHTML = '';

        newsCards.forEach((card: NewsCard) => {
            const cardDiv = document.createElement('div');
            cardDiv.classList.add('news-listing__card', 'flex', 'flex--column');

            if (card.imageUrl) {
                const image = document.createElement('img');
                image.classList.add('news-listing__card-image');
                image.src = card.imageUrl;
                image.alt = card.imageAlt;
                cardDiv.appendChild(image);
            }

            if (card.title) {
                const title = document.createElement('h5');
                title.classList.add('news-listing__card-title');
                title.innerText = card.title;
                cardDiv.appendChild(title);
            }

            const cardBottomDiv = document.createElement('div');
            cardBottomDiv.classList.add(
                'news-listing__card-bottom',
                'flex',
                'flex--row',
                'flex--justify-between',
                'flex--align-center',
            );

            if (card.readTime) {
                const readTime = document.createElement('p');
                readTime.classList.add('news-listing__card-read');
                readTime.innerText = card.readTime;
                cardBottomDiv.appendChild(readTime);
            }

            const cardButtonText = document.createElement('a');
            cardButtonText.classList.add(
                'news-listing__button',
                'flex',
                'flex--row',
                'flex--justify-center',
                'flex--align-center',
            );
            cardButtonText.href = card.url;
            cardButtonText.innerText = card.buttonText;

            const arrowSpan = document.createElement('span');
            arrowSpan.classList.add('zmdi', 'zmdi-long-arrow-right');

            cardButtonText.appendChild(arrowSpan);
            cardBottomDiv.appendChild(cardButtonText);
            cardDiv.appendChild(cardBottomDiv);
            grid.appendChild(cardDiv);
        });
    };

    const hookListeners = () => {
        nextButton.addEventListener('click', () => {
            ++pageNumber;
            fetchNews();
        });

        prevButton.addEventListener('click', () => {
            --pageNumber;
            fetchNews();
        });
    };

    const updatePagination = () => {
        if (isLoading) {
            nextButton.disabled = prevButton.disabled = true;
        } else {
            nextButton.disabled = pageNumber >= pagesCount;
            prevButton.disabled = pageNumber === 1;
        }
    };

    const updateUI = () => {
        updateCards();
        updatePagination();
    };

    const init = () => {
        fetchNews();
        hookListeners();
    };

    document.addEventListener('DOMContentLoaded', init);
}
