const orgDirectorForm = document.querySelector(".org-director__form") as HTMLFormElement;

if (orgDirectorForm) {
  const resetValidation = () => {
    validations.forEach(item => {
      item.classList.remove("org-director__validation--active");
    })
  }

  const inputs: NodeListOf<HTMLInputElement> = orgDirectorForm.querySelectorAll(".org-director__input");
  const validations: NodeListOf<HTMLSpanElement> = orgDirectorForm.querySelectorAll(".org-director__validation");
  const director1Input = document.querySelector(".org-director__director-1") as HTMLInputElement;
  const director2Input = document.querySelector(".org-director__director-2") as HTMLInputElement;
  const director3Input = document.querySelector(".org-director__director-3") as HTMLInputElement;
  const director1Validation = document.querySelector(".org-director__validation-director-1") as HTMLElement;

  inputs.forEach(input => {
    input.addEventListener("input", () => {
      input.nextElementSibling.classList.remove("org-director__validation--active")
    })
  });

  orgDirectorForm.addEventListener("submit", async e => {
    resetValidation()

    const formValues = {
      director1: director1Input.value,
      director2: director2Input.value,
      director3: director3Input.value,
    };

    if(!director1Input.value) {      
      director1Validation.classList.add("org-director__validation--active")
    }

    if (director1Input.value) {
      resetValidation()
    } else {
      e.preventDefault()
    }
  });
}