const transferSummaryComponent = document.querySelector(".transfer-summary__wrapper") as HTMLDivElement;

if (transferSummaryComponent) {
  const transferSummaryLeft = transferSummaryComponent.querySelector(".transfer-summary__left") as HTMLDivElement;
  const transferSummaryNextButton = transferSummaryComponent.querySelector(".transfer-summary__button") as HTMLButtonElement;

  const showTransferSummary = () => {
    transferSummaryLeft.classList.remove("transfer-summary__left--active")

    const currentPayoutMethod = (document.querySelector("input[name='payment-method']:checked") as HTMLInputElement).value;
    document.querySelector(`.transfer-summary__right[data-type='${currentPayoutMethod}']`)?.classList.add("transfer-summary__right--active");

    document.querySelector
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  transferSummaryNextButton.addEventListener("click", () => {
    showTransferSummary()
  })
}