import Glide from '@glidejs/glide'

const mediaCarousels: NodeListOf<Element> = document.querySelectorAll(".media-carousel__carousel-inner");

if(mediaCarousels.length > 0){

  const mediaCarouselsArray = Array.from(mediaCarousels) || []

  mediaCarouselsArray.forEach((item: HTMLElement) => {
    const glide = new Glide(item, {
      type: 'carousel',
      perView: 1.779,
      gap: 20,
      rewind: true,
      bound: true,
      focusAt: "center",
      breakpoints: {
        480: {
          perView: 1.357,
        },
      }
    })
    
    glide.mount()
  })
}