import setMaxDateValue from '../../../helpers/dateMaxValue';

const orgProfileMemberForm = document.querySelector(".org-member-profile__form") as HTMLFormElement;

if (orgProfileMemberForm) {
  const firstNameInput = orgProfileMemberForm.querySelector(".org-member-profile__first-name") as HTMLInputElement;
  const middleNameInput = orgProfileMemberForm.querySelector(".org-member-profile__middle-name") as HTMLInputElement;
  const lastNameInput = orgProfileMemberForm.querySelector(".org-member-profile__last-name") as HTMLInputElement;
  const dateInput = orgProfileMemberForm.querySelector(".org-member-profile__dob") as HTMLInputElement;
  const countryInput = orgProfileMemberForm.querySelector(".org-member-profile__country") as HTMLSelectElement;

  const validations: NodeListOf<HTMLSpanElement> = orgProfileMemberForm.querySelectorAll(".org-member-profile__validation");

  const firstNameValidation = orgProfileMemberForm.querySelector(".org-member-profile__validation-first-name") as HTMLSpanElement;
  const middleNameValidation = orgProfileMemberForm.querySelector(".org-member-profile__validation-middle-name") as HTMLSpanElement;
  const lastNameValidation = orgProfileMemberForm.querySelector(".org-member-profile__validation-last-name") as HTMLSpanElement;
  const dateValidation = orgProfileMemberForm.querySelector(".org-member-profile__validation-dob") as HTMLSpanElement;
  const countryValidation = orgProfileMemberForm.querySelector(".org-member-profile__validation-country") as HTMLSpanElement;

  let isFormValid = false;

  const resetValidation = () => {
    validations.forEach(item => {
      item.classList.remove("org-member-profile__validation--active")
    })
  }

  const resetForm = () => {
    firstNameInput.value = ""
    lastNameInput.value = ""
    middleNameInput.value = ""
    dateInput.value = ""
    countryInput.value = ""

    countryInput.classList.remove("org-member-profile__country--active")
    dateInput.classList.remove("org-member-profile__dob--active")
  }

  const nameValidation = (name: string) => {
    const regex = /^[\u0600-\u06FFa-zA-Z\s'-]+$/;
    return regex.test(name);
};

  const validateForm = () => {
    isFormValid = true;

    if (!firstNameInput.value || !nameValidation(firstNameInput.value)) {
      firstNameValidation.classList.add("org-member-profile__validation--active")
      firstNameValidation.textContent = "First name is required and must contain only letters"
      isFormValid = false;
    }

    if (middleNameInput.value && !nameValidation(middleNameInput.value)) {
      middleNameValidation.classList.add("org-member-profile__validation--active")
      middleNameValidation.textContent = "Middle name must contain only letters"
      isFormValid = false;
    }

    if (!lastNameInput.value || !nameValidation(lastNameInput.value)) {
      lastNameValidation.classList.add("org-member-profile__validation--active")
      lastNameValidation.textContent = "Last name is required and must contain only letters"
      isFormValid = false;
    }

    if (!dateInput.value) {
      dateValidation.classList.add("org-member-profile__validation--active")
      isFormValid = false;
    }

    if (!countryInput.value) {
      countryValidation.classList.add("org-member-profile__validation--active")
      isFormValid = false;
    }

    return isFormValid;
  }

  const listenForInputChanges = () => {
    firstNameInput.addEventListener("input", () => {
      firstNameValidation.classList.remove("org-member-profile__validation--active")
    })

    lastNameInput.addEventListener("input", () => {
      lastNameValidation.classList.remove("org-member-profile__validation--active")
    })

    dateInput.addEventListener("input", () => {
      dateInput.classList.add("org-member-profile__dob--active")
      dateValidation.classList.remove("org-member-profile__validation--active")
    })
    countryInput.addEventListener("input", () => {
      countryValidation.classList.remove("org-member-profile__validation--active")
      countryInput.classList.add("org-member-profile__country--active")
    })
  }

  listenForInputChanges()
  dateInput.setAttribute("max", setMaxDateValue());

  orgProfileMemberForm.addEventListener("submit", async e => {

    resetValidation()

    const formValues = {
      firstName: firstNameInput.value,
      middleName: middleNameInput.value,
      lastName: lastNameInput.value,
      dateOfBirth: dateInput.value,
      countryOfBirth: countryInput.value,
    };

    validateForm()

    if (isFormValid) {
      isFormValid = false
    }
    else {
      e.preventDefault();
    }
  });
}