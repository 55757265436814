const orgProfile = document.querySelector(".org-profile__wrapper") as HTMLDivElement;

if (orgProfile) {
  const orgProfileCards: NodeListOf<HTMLDivElement> = document.querySelectorAll(".org-profile__card");
  const orgProfileForms: NodeListOf<HTMLFormElement> = document.querySelectorAll(".org-profile__form");
  const orgProfileButton = document.querySelector(".org-profile__next") as HTMLButtonElement;

  const resetRegisterCards = () => {
    orgProfileCards.forEach(card => {
      card.classList.remove("org-profile__card--active")
    })

    orgProfileForms.forEach(card => {
      card.classList.remove("org-profile__form--active")
    })
  }

  const getCorrectInputs = () => {
    const activeForm = orgProfile.querySelector(".org-profile__form--active") as HTMLFormElement;

    const inputName = activeForm.querySelector(".org-profile__name") as HTMLInputElement;
    const inputNumber = activeForm.querySelector(".org-profile__number") as HTMLInputElement;

    var isBusinessNameValid = false;
    var isBusinessNumberValid = false;

    const numberValidation = (name: string) => {
      const regex = /^\d+$/;
      return regex.test(name);
    };

    const validateBusinessName = (input: HTMLInputElement) => {
      const parent = input.parentElement as HTMLDivElement;
      const orgProfileNameValidation = parent.querySelector(".org-profile__validation-business-name") as HTMLDivElement;

      if (input.value.length > 0) {
        orgProfileNameValidation.classList.remove("org-profile__validation--active");
        isBusinessNameValid = true;
      } else {
        orgProfileNameValidation.classList.add("org-profile__validation--active");
        isBusinessNameValid = false;
      }
    };

    const validateBusinessNumber = (input: HTMLInputElement) => {
      const parent = input.parentElement as HTMLDivElement;
      const orgProfileNumberValidation = parent.querySelector(".org-profile__validation-business-number") as HTMLDivElement;

      if (input.value.length > 0 && numberValidation(input.value)) {
        orgProfileNumberValidation.classList.remove("org-profile__validation--active");
        isBusinessNumberValid = true;
      } else {
        orgProfileNumberValidation.classList.add("org-profile__validation--active");
        isBusinessNumberValid = false;
      }
    };

    const toggleSubmitButton = () => {
      if (isBusinessNameValid && isBusinessNumberValid) {
        orgProfileButton.disabled = false;
      } else {
        orgProfileButton.disabled = true;
      }
    };

    inputName.addEventListener("input", (e: Event) => {
      const target = e.target as HTMLInputElement;
      validateBusinessName(target);
      toggleSubmitButton();
    });

    inputNumber.addEventListener("input", (e: Event) => {
      const target = e.target as HTMLInputElement;
      validateBusinessNumber(target);
      toggleSubmitButton();
    });
  }

  const setActiveCard = (item: HTMLDivElement, index: number) => {
    resetRegisterCards()
    item.classList.add("org-profile__card--active")
    orgProfileForms[index].classList.add("org-profile__form--active");
    getCorrectInputs()
  }

  if (orgProfileCards.length > 0) {
    orgProfileCards[0].classList.add("org-profile__card--active");
    orgProfileForms[0].classList.add("org-profile__form--active");

    getCorrectInputs()

    orgProfileCards.forEach((card: HTMLDivElement, i: number) => {
      card.addEventListener("click", () => {
        setActiveCard(card, i)
      })

      card.addEventListener("keydown", e => {
        const keyboardEvent = <KeyboardEvent>e;

        if (keyboardEvent.key === "Enter") {
          setActiveCard(card, i)
        }
      })
    })

    document.querySelector(".org-profile__next").addEventListener("click", function (e) {
      e.preventDefault();
      let activeForm = document.querySelector(".org-profile__form--active") as HTMLFormElement;
      activeForm.submit();
    });
  }
}