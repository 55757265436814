import Glide from '@glidejs/glide'

const newsGridCarousels: NodeListOf<Element> = document.querySelectorAll(".news-grid__carousel-inner");

const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
  const slidesLength = Array.from(arr).length
  let rangeMax = '0'
  
  rangeMax = (slidesLength - 1).toString()

  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");

  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}

if(newsGridCarousels.length > 0){

  const newsGridCarouselsArray = Array.from(newsGridCarousels) || []

  newsGridCarouselsArray.forEach((item: HTMLElement) => {
    const glide = new Glide(item, {
      type: 'slider',
      perView: 1.24,
      gap: 10,
      rewind: false,
      bound: true,
    })
    
    glide.mount()
  
    const carouselRange = item.querySelector(".news-grid__carousel-range") as HTMLInputElement
    const slides: NodeListOf<Element> = item.querySelectorAll(".news-grid__card");
  
    carouselRange.addEventListener("input", e => {
        setTimeout(() => {
          glide.go(`=${carouselRange.value}`)
        }, 500);
    })
  
    glide.on('move.after', () =>{
      const currentIndex = glide.index;
      carouselRange.value = currentIndex
    })
  
    setRangeMax(slides, carouselRange)
  })
}