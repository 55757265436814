import Glide from '@glidejs/glide'

const ctaCardsCarousels = document.querySelectorAll(".cta-cards__carousel-inner")

const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
  const slidesLength = Array.from(arr).length
  let rangeMax = '0'
  
  rangeMax = (slidesLength - 1).toString()

  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");

  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}

if(ctaCardsCarousels){

  const ctaCardsCarouselsArray = Array.from(ctaCardsCarousels) || []

  ctaCardsCarouselsArray.forEach(item => {
    var glide = new Glide(item, {
      type: 'slider',
      perView: 1.7,
      gap: 10,
      rewind: false,
      bound: true,
      breakpoints: {
        550: {
          perView: 1.24,
        },
      }
    })
    
    glide.mount()
  
    const carouselRange = item.querySelector(".cta-cards__carousel-range") as HTMLInputElement
    const slides = item.querySelectorAll(".cta-cards__card")
  
    carouselRange.addEventListener("input", e => {
        setTimeout(() => {
          glide.go(`=${carouselRange.value}`)
        }, 500);
    })
  
    glide.on('move.after', () =>{
      const currentIndex = glide.index;
      carouselRange.value = currentIndex
    })
  
    setRangeMax(slides, carouselRange)
  })
}