const forgotPassForm = document.querySelector(".individual-forgot-pass__form") as HTMLFormElement;

if (forgotPassForm) {
  const forgotEmailInput = document.querySelector(".individual-forgot-pass__email") as HTMLInputElement;
  const forgotEmailValidation = document.querySelector(".individual-forgot-pass__validation-email") as HTMLElement;

  const resetForgotPasswordValidation = function () {
    forgotEmailValidation.classList.remove("individual-forgot-pass__validation--active")
  }

  forgotEmailInput.addEventListener("input", () => {
    forgotEmailValidation.classList.remove("individual-forgot-pass__validation--active")
  })

  forgotPassForm.addEventListener("submit", async e => {
    
    resetForgotPasswordValidation()
    
    const formValues = {
      email: forgotEmailInput.value,
    };
    
    if (!forgotEmailInput.value) {
      forgotEmailValidation.classList.add("individual-forgot-pass__validation--active")
    }
    
    if (forgotEmailInput.value) {
      resetForgotPasswordValidation()
    }
    else {
      e.preventDefault();
    }
  });
}