const sendFundsButton = document.querySelector(".navbar__button-funds") as HTMLElement
const sendFunds = document.querySelector(".send-funds__overlay") as HTMLElement
const sendFundsMobileButton = document.querySelector(".navbar__sticky-button") as HTMLElement
const htmlElement: HTMLElement | null = document.documentElement;
const navbar = document.querySelector(".navbar") as HTMLElement;

const pageLinkCurrent = (link: string) => {
  let currentUrl = window.location.pathname.split("/")[1];

  if (currentUrl) {
    return link.includes(currentUrl);
  }

  return false
}

if(sendFundsButton){
  sendFundsButton.addEventListener("click", e => {
    if(sendFunds.classList.contains("send-funds__overlay--open")){
      sendFunds.classList.remove("send-funds__overlay--active")
      htmlElement.style.overflow="auto"
    }else {
      sendFunds.classList.add("send-funds__overlay--active")
      htmlElement.style.overflow="hidden"
    }
  })
}

if(sendFundsMobileButton){
  sendFundsMobileButton.addEventListener("click", e => {
    if(sendFunds.classList.contains("send-funds__overlay--open")){
      sendFunds.classList.remove("send-funds__overlay--active")
      htmlElement.style.overflow="auto"
    }else {
      sendFunds.classList.add("send-funds__overlay--active")
      htmlElement.style.overflow="hidden"
    }
  })
}

if(navbar) {
  const links: NodeListOf<HTMLAnchorElement> = navbar.querySelectorAll(".navbar__links-item .link");

  links.forEach(link => {
    const href: string = link.href

    if(pageLinkCurrent(href)) {
      link.classList.add("link--active")
    }
  })
}