import axios from "axios";

const paymentsComponent = document.querySelector('.payments__wrapper') as HTMLDivElement;

if (paymentsComponent) {
    const paymentsList = paymentsComponent.querySelector('.payments__list') as Element;
    const paymentsRight = paymentsComponent.querySelector('.payments__right') as HTMLDivElement;
    const searchInput = paymentsComponent.querySelector('.payments__search') as HTMLInputElement;
    const paymentDates = paymentsComponent.querySelector('.payments__dates') as HTMLDivElement;
    const dateInput1 = paymentDates.querySelector('.payments__date-1') as HTMLInputElement;
    const dateInput2 = paymentDates.querySelector('.payments__date-2') as HTMLInputElement;
    const paymentDateButton = paymentsComponent.querySelector('.payments__button') as HTMLButtonElement;

    let reachedEnd: boolean = false;
    let lastTransactionId = 0;
    let increment: number = 12;
    let fetchingData: boolean = false;
    let startDate: string | null = null;
    let endDate: string | null = null;
    let searchTerm: string | null = null;
    let db: any[] = [];

    const fetchData = async (isScroll: boolean) => {
        if (fetchingData || reachedEnd) {
            return;
        }

        fetchingData = true;

        showLoader();

        try {
            // For development:
            // const response = (await axios.get("https://localhost:44323/api/payments/getAllPayments", {
            //     params: {
            //         pageIncrement: increment,
            //         lastTransactionId,
            //         startDate,
            //         endDate,
            //         searchTerm
            //     },
            // })).data;

            const response = (await axios.get("/api/payments/getAllPayments", {
                params: {
                    pageIncrement: increment,
                    lastTransactionId,
                    startDate,
                    endDate,
                    searchTerm
                },
            })).data;

            lastTransactionId = response.lastTransactionId;
            reachedEnd = response.reachedEnd;
            const newData = response.transactions;
            db.push(...newData);

            generateHtml(db);

            if (reachedEnd) {
                paymentsList.innerHTML +=
                    '<li class="payments__item-caption flex flex--align-center flex--justify-center">End of results</li>';
            }

            fetchingData = false;

            hideLoader();

            afterData();

            if (isScroll) {
                const scrollThreshold = 900;
                const bottomScrollPosition = paymentsList.scrollHeight - scrollThreshold;
                paymentsList.scrollTo({ top: bottomScrollPosition, behavior: 'smooth' });
            }

            fetchingData = false;
        } catch (error) {
            console.log(error);
        }
    };

    const generateHtml = (data) => {
        renderList(data);
        renderPayment(data);
    };

    const renderList = (data) => {
        const container: Element = paymentsList;
        if (!container) return;

        container.innerHTML =
            '<li class="payments__loader"> <img src="/assets/img/img/loader-icon.png" alt="loading icon" class="payments__loader-icon spin" /></li>';

        data.forEach((item) => {
            const html = renderListItem(item);
            container.innerHTML += html;
        });
    };

    const formatAmount = (amount: string): string => {
        const numericAmount = parseFloat(amount.toString().replace(/[^0-9.-]+/g, ''));
        
        return numericAmount.toFixed(2);
    };
    
    const formatAmountWithCurrency = (item) => {
        const formattedAmount = formatAmount(item.amount.value);
        const currencySymbol = item.amount.currencySymbol;
        return formattedAmount.includes(currencySymbol) ? formattedAmount : `${currencySymbol}${formattedAmount}`;
    };

const renderListItem = (item) => {
    const status: string = item.status || ""
    item.amount.value = formatAmountWithCurrency(item);

    const html = `
            <li class="payments__item fade-in-slow flex flex--align-center" tabindex="0">
                <p class="payments__item-title">${item.title}</p>
                <div class="payments__item-info flex flex--align-center">
                    <p class="payments__item-amount">${item.amount.value}</p>

                    ${status === "delivered" ? (
                        `
                        <div class="payments__item-status flex flex--align-center">
                            <i class="zmdi zmdi-check-circle"></i>
                        </div>
                        `
                    ) : ""}

                    ${status === "rejected" ? (
                        `
                        <div class="payments__item-status flex flex--align-center">
                            <i class="zmdi zmdi-close-circle"></i>
                        </div>
                        `
                    ) : ""}

                    ${status === "in progress" ? (
                        `
                        <div class="payments__item-status flex flex--align-center">
                            <div class="payments__item-dash">
                                <div class="payments__item-dash-inner" style="--itemProgress: 20%;"></div>
                            </div>
                        </div>
                        `
                    ) : ""}

                    ${status === "pending" ? (
                        `
                        <div class="payments__item-status flex flex--align-center">
                            <div class="payments__item-dash">
                                <div class="payments__item-dash-inner" style="--itemProgress: 50%;"></div>
                            </div>
                        </div>
                        `
                    ) : ""}

                    <p class="payments__item-date">${item.dateValue}</p>
                </div>
                <i class="zmdi zmdi-chevron-right"></i>
            </li>
`;

    return html;
};

    const renderPayment = (data) => {
        const container = paymentsRight;

        if (!container) return;

        container.innerHTML = '';

        data.forEach((item) => {
            const html = renderPaymentItem(item);
            container.innerHTML += html;
        });
    };

    const renderPaymentItem = (item) => {
        const payAgainText: string = paymentsRight.getAttribute('data-pay-again');
        const receiptText: string = paymentsRight.getAttribute('data-receipt');
        const paymentTitle: string = paymentsRight.getAttribute('data-pay-title');
        const paymentCaption: string = paymentsRight.getAttribute('data-pay-caption');
        const transferPurposeTitle: string = paymentsRight.getAttribute('data-purpose');
        const sentText: string = paymentsRight.getAttribute('data-sent');
        const exchangeRateText: string = paymentsRight.getAttribute('data-exchange');
        const totalPaidText: string = paymentsRight.getAttribute('data-total');
        const payoutCountryText: string = paymentsRight.getAttribute('data-country');
        const payoutMethodText: string = paymentsRight.getAttribute('data-method');
        const amanacardNumberText: string = paymentsRight.getAttribute('data-number');
        const paymentMethodText: string = paymentsRight.getAttribute('data-payment-method');
        const referenceNumberText: string = paymentsRight.getAttribute('data-reference-number');

        const status: string = item.status || "";
        const lastUpdated: string = item.lastUpdated || "";
        const paymentInProgressStatusTitle: string = paymentsRight.getAttribute('data-status-in-progress-title') || "";
        const paymentInProgressStatusSubtitle: string = paymentsRight.getAttribute('data-status-in-progress-subtitle') || "";
        const paymentPendingStatusTitle: string = paymentsRight.getAttribute('data-status-pending-title') || "";
        const paymentPendingStatusSubtitle: string = paymentsRight.getAttribute('data-status-pending-subtitle') || "";
        const paymentRejectedStatusTitle: string = paymentsRight.getAttribute('data-status-rejected-title') || "";
        const paymentRejectedStatusSubtitle: string = paymentsRight.getAttribute('data-status-rejected-subtitle') || "";
        const paymentDeliveredStatusTitle: string = paymentsRight.getAttribute('data-status-delivered-title') || "";
        const paymentDeliveredStatusSubtitle: string = paymentsRight.getAttribute('data-status-delivered-subtitle') || "";
        const lastUpdatedText: string = paymentsRight.getAttribute('data-last-updated') || "";

        const html = `
        <div class="payments__details fade-in-slow flex flex--column flex--align-start">
            <div class="payments__header flex flex--wrap flex--justify-between flex--align-center">
                <p class="payments__date">
                    ${item.dateValue}
                </p>
                <div class="payments__buttons flex flex--wrap flex--justify-between">
                    ${payAgainText && item.payAgainUrl && (
                        `<a href="${item.payAgainUrl}" class="link link--primary flex flex--justify-center flex--align-center">
                            <span class="link__label">${payAgainText}</span>
                        </a>`
                    )}
                    ${receiptText && item.receiptUrl && (
                        `<a href="${item.receiptUrl}" class="link link--primary flex flex--justify-center flex--align-center">
                            <span class="link__label">${receiptText}</span>
                        </a>`
                    )}
                </div>
            </div>
            <div class="payments__transaction flex flex--column flex--align-center flex--justify-center">
                <div class="payments__amounts flex flex--wrap flex--align-center flex--justify-center">
                    <div class='payments__amount flex flex--column flex--align-center'>
                        <span class='payments__value'>
                        ${item.amount.value}
                        </span>
                        <span class='payments__currency'>
                            ${item.amount1.currency}
                        </span>
                    </div>
                    <i class='zmdi zmdi-long-arrow-right'></i>
                    <div class='payments__amount flex flex--column flex--align-center'>
                        <span class='payments__value'>
                            ${item.amount2.value}
                        </span>
                        <span class='payments__currency'>
                            ${item.amount2.currency}
                        </span>
                    </div>
                </div>
                <p class="payments__text flex flex--align-center flex--justify-center">
                    ${paymentTitle}
                </p>
                <div class="payments__item-dash"></div>
                <p class="payments__caption flex flex--align-center flex--justify-center">
                    ${paymentCaption}
                </p>
            </div>

            ${status === "in progress" ? (
                `
                <div class="payments__step flex flex--column flex--align-center flex--justify-center">
                    <h5 class="payments__step-title">${paymentInProgressStatusTitle}</h5>
                    <div class="payments__step-progress-bar">
                        <div class="payments__step-progress" style="--progress: 20%;"></div>
                    </div>
                    <p class="payments__step-subtitle">${paymentInProgressStatusSubtitle}</p>
                    <p class="payments__step-text">${lastUpdatedText} ${lastUpdated}</p>
                </div>
                `
            ) : ""}
            
            ${status === "pending" ? (
                `
                <div class="payments__step flex flex--column flex--align-center flex--justify-center">
                    <h5 class="payments__step-title">${paymentPendingStatusTitle}</h5>
                    <div class="payments__step-progress-bar">
                        <div class="payments__step-progress" style="--progress: 50%;"></div>
                    </div>
                    <p class="payments__step-subtitle">${paymentPendingStatusSubtitle}</p>
                    <p class="payments__step-text">${lastUpdatedText} ${lastUpdated}</p>
                </div>
                `
            ) : ""}
            
            ${status === "rejected" ? (
                `
                <div class="payments__step flex flex--column flex--align-center flex--justify-center">
                    <h5 class="payments__step-title">${paymentRejectedStatusTitle}</h5>
                    <div class="payments__step-progress-bar">
                        <div class="payments__step-progress payments__step-progress--failed" style="--progress: 100%;"></div>
                    </div>
                    <p class="payments__step-subtitle">${paymentRejectedStatusSubtitle}</p>
                    <p class="payments__step-text">${lastUpdatedText} ${lastUpdated}</p>
                </div>
                `
            ) : ""}
            
            ${status === "delivered" ? (
                `
                <div class="payments__step flex flex--column flex--align-center flex--justify-center">
                    <h5 class="payments__step-title">${paymentDeliveredStatusTitle}</h5>
                    <div class="payments__step-progress-bar">
                        <div class="payments__step-progress payments__step-progress--complete" style="--progress: 100%;"></div>
                    </div>
                    <p class="payments__step-subtitle">${paymentDeliveredStatusSubtitle}</p>
                    <p class="payments__step-text">${lastUpdatedText} ${lastUpdated}</p>
                </div>
                `
            ) : ""}

            <div class="payments__info flex flex--column flex--align-center flex--justify-center">
                <p class="payments__info-title">${transferPurposeTitle}</p>
                <p class="payments__info-text">${item.transferPurposeText}</p>
            </div>
            <ul class="payments__content flex flex--column flex--align-start flex--justify-center">
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${sentText}</p>
                    <p class="payments__row-text">${item.amount.value}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${exchangeRateText}</p>
                    <p class="payments__row-text">${item.exchangeRate}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${totalPaidText}</p>
                    <p class="payments__row-text">${item.totalPaid}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${paymentMethodText}</p>
                    <p class="payments__row-text">${item.paymentMethod}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${referenceNumberText}</p>
                    <p class="payments__row-text">${item.referenceNumber}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${payoutCountryText}</p>
                    <p class="payments__row-text">${item.payoutCountry}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${payoutMethodText}</p>
                    <p class="payments__row-text">${item.payoutMethod}</p>
                </li>
                <li class="payments__row flex flex--align-center flex--justify-between">
                    <p class="payments__row-title">${amanacardNumberText}</p>
                    <p class="payments__row-text">${item.amanacardNumber}</p>
                </li>
            </ul>
            <div class="payments__mobile-buttons flex flex--wrap flex--align-center flex--justify-center">
                <button class="button button--primary">Receipt</button>
                <button class="button button--primary">${payAgainText}</button>
            </div>
        </div>
    `;

        return html;
    };

    const isScrolledToBottom = () => {
        const scrollThreshold: number = 100;

        return paymentsList.scrollTop + paymentsList.clientHeight >= paymentsList.scrollHeight - scrollThreshold;
    };

    paymentsList.addEventListener('scroll', () => {
        if (isScrolledToBottom()) {
            fetchData(true);
        }
    });

    const resetDb = () => {
        db = [];
        reachedEnd = false;
        lastTransactionId = null;
    }

    dateInput1.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement;
        startDate = formatDate(new Date(target.value)) as string;

        const today = formatDate(new Date()) as string;
        dateInput1.max = today
        dateInput2.max = today
        dateInput2.min = startDate

        if(startDate > endDate){
            dateInput2.value = startDate;
        } else {
            dateInput1.max = endDate
        }

        resetDb();
        fetchData(false);
    });

    dateInput2.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement;
        endDate = formatDate(new Date(target.value)) as string;
        dateInput1.max = endDate

        if(startDate > endDate) {
            dateInput1.value = endDate
        }

        resetDb();
        fetchData(false);
    });

    paymentDateButton.addEventListener('click', () => {
        if (paymentDates.classList.contains('payments__dates--active')) {
            paymentDates.classList.remove('payments__dates--active');
            paymentDateButton.classList.remove("payments__button--active")
        } else {
            paymentDates.classList.add('payments__dates--active');
            paymentDateButton.classList.add("payments__button--active")
        }
    });

    const showLoader = () => {
        const paymentsLoader = paymentsComponent.querySelector('.payments__loader') as HTMLDivElement;
        const items: NodeListOf<Element> = paymentsComponent.querySelectorAll('.payments__item');

        items.forEach((item) => {
            item.classList.add('payments__item--hidden');
        });

        paymentsLoader.classList.add('payments__loader--active');
    };

    const hideLoader = () => {
        const paymentsLoader = paymentsComponent.querySelector('.payments__loader') as HTMLDivElement;
        const items: NodeListOf<Element> = paymentsComponent.querySelectorAll('.payments__item');

        items.forEach((item) => {
            item.classList.remove('payments__item--hidden');
        });

        paymentsLoader.classList.remove('payments__loader--active');
    };

    const formatDate = (date: Date) => {
        return date.toISOString().split('T')[0];
    };

    const afterData = () => {
        const paymentsBack = paymentsComponent.querySelector('.payments__back') as HTMLButtonElement;
        const paymentCards: NodeListOf<Element> = paymentsComponent.querySelectorAll('.payments__item');

        const resetCards = () => {
            const newPaymentCards: NodeListOf<Element> = paymentsComponent.querySelectorAll('.payments__item');

            newPaymentCards.forEach((card: Element) => {
                card.classList.remove('payments__item--active');
            });
        };

        const resetDetails = () => {
            const newPaymentDetails: NodeListOf<HTMLDivElement> =
                paymentsComponent.querySelectorAll('.payments__details');

            newPaymentDetails.forEach((item: HTMLDivElement) => {
                paymentsRight.classList.remove('payments__right--active');
                item.classList.remove('payments__details--active');
            });

            paymentsBack.classList.remove('payments__back--active');
        };

        const handleCardSelect = (card: Element, index: number) => {
            const newPaymentDetails: NodeListOf<HTMLDivElement> =
                paymentsComponent.querySelectorAll('.payments__details');

            resetCards();
            resetDetails();

            card.classList.add('payments__item--active');
            paymentsRight.classList.add('payments__right--active');
            newPaymentDetails[index].classList.add('payments__details--active');
            paymentsBack.classList.add('payments__back--active');

            if (window.innerWidth < 768) {
                newPaymentDetails[index].focus();

                window.scrollTo({ top: 0, behavior: 'smooth' });

                newPaymentDetails[index].addEventListener('keydown', (e) => {
                    const keyboardEvent = <KeyboardEvent>e;

                    if (keyboardEvent.key === 'Escape') {
                        resetCards();
                        resetDetails();
                    }
                });
            }
        };

        if (window.innerWidth > 768) {
            const newPaymentDetails: NodeListOf<HTMLDivElement> =
                paymentsComponent.querySelectorAll('.payments__details');
            const newPaymentCards: NodeListOf<Element> = paymentsComponent.querySelectorAll('.payments__item');

            if (newPaymentDetails.length > 0) {
                newPaymentDetails[0].classList.add('payments__details--active');
                newPaymentCards[0].classList.add('payments__item--active');
            }
        }

        let timeoutId: NodeJS.Timeout;

        searchInput.addEventListener('input', (e: Event) => {
            const target = e.target as HTMLInputElement;
            searchTerm = target.value.trim().toLowerCase();

            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                resetDb();
                fetchData(false);
            }, 1000);
        });

        paymentCards.forEach((card: Element, i: number) => {
            card.addEventListener('click', () => {
                handleCardSelect(card, i);
            });

            card.addEventListener('keydown', (e) => {
                const keyboardEvent = <KeyboardEvent>e;

                if (keyboardEvent.key === 'Enter') {
                    handleCardSelect(card, i);
                }
            });
        });

        paymentsBack.addEventListener('click', () => {
            resetCards();
            resetDetails();
        });
    };

    const setDefaultDates = () => {
        const today = new Date() as Date;
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        if (oneMonthAgo.getMonth() === 1 && oneMonthAgo.getDate() === 29) {
            oneMonthAgo.setDate(28);
        }

        endDate = formatDate(today) as string;
        startDate = formatDate(oneMonthAgo) as string;

        dateInput1.value = startDate;
        dateInput2.value = endDate;
        dateInput1.min = formatDate(new Date(2000, 0, 1))
        dateInput2.min = startDate
        dateInput1.max = endDate
        dateInput2.max = endDate
    };

    setDefaultDates();

    fetchData(false);
}
