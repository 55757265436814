import renderUpload from '../../../helpers/individualUpload';

const individualAuthProof: NodeListOf<Element> = document.querySelectorAll(".individual-auth__proof");
const individualAuthDocumentTypes: NodeListOf<Element> = document.querySelectorAll(".document-type");

if(individualAuthProof.length > 0 && individualAuthDocumentTypes.length > 0){
  const individualAuth: HTMLDivElement = document.querySelector(".individual-auth");
  const individualAuthRight: HTMLDivElement = document.querySelector(".individual-auth__right");
  const individualAuthLeft: HTMLDivElement = document.querySelector(".individual-auth__left");
  const individualAuthButton: HTMLButtonElement = document.querySelector(".individual-auth__button");
  const individualAuthProofDocs: NodeListOf<Element> = document.querySelectorAll(".individual-auth__proof-docs");
  const individualAuthUploadButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".individual-auth__upload-button");
  const individualAuthUploadWrappers: NodeListOf<HTMLDivElement> = document.querySelectorAll(".individual-upload__wrapper")

  if(window.innerWidth > 992) {
    individualAuthProof[0].classList.add("individual-auth__proof--active")
    individualAuthDocumentTypes[0].classList.add("document-type--active")
  }

  const resetIndividualAuthCards = () => {
    individualAuthDocumentTypes.forEach(card => {
      card.classList.remove("document-type--active")
    })

    individualAuthProof.forEach(item => {
      item.classList.remove("individual-auth__proof--active")
    })

    individualAuthRight.classList.remove("individual-auth__right--active")
    individualAuthLeft.classList.remove("individual-auth__left--hidden")
  }

  const resetIndividualAuthDocs = () => {
    individualAuthProofDocs.forEach(card => {
      card.classList.remove("individual-auth__proof-docs--active")
    })
  }

  const individualAuthRightScroll = () => {
    const topOffset = 70; 
    const elementTop = individualAuthRight.getBoundingClientRect().top + window.scrollY;
    const scrollPosition = elementTop - topOffset;

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  }

  const showActiveIndividualProof = (item: Element, index: number) => {
    resetIndividualAuthCards()
      
    item.classList.add("document-type--active")
    individualAuthProof[index].classList.add("individual-auth__proof--active")
    individualAuthRight.classList.add("individual-auth__right--active")
    individualAuthLeft.classList.add("individual-auth__left--hidden")

    if(window.innerWidth < 768) {
      individualAuthRightScroll()
    }
  }

  const handleProofDocClick = (doc: HTMLDivElement) => {
    let activeIndex = 0

    individualAuthDocumentTypes.forEach((doc: HTMLDivElement, i: number) => {
      if(doc.classList.contains("document-type--active")){
        activeIndex = i
      }
    })

    individualAuthUploadButtons[activeIndex].removeAttribute("disabled")

    resetIndividualAuthDocs()
    
    doc.classList.add("individual-auth__proof-docs--active")

    individualAuthUploadButtons[activeIndex].addEventListener("click", () => {
      individualAuthUploadWrappers.forEach(item => {
        item.classList.remove("individual-upload__wrapper--active")
      })

      const individualAuthUploadWrapperActive = doc.nextElementSibling
      individualAuthUploadWrapperActive.classList.add("individual-upload__wrapper--active")

      // Set individualAuth height same as activeUploadLeft
      const individualUploadLeftActive = document.querySelector<HTMLElement>(".individual-upload__wrapper--active .individual-upload__left") as HTMLDivElement | null;
      
      individualAuth.style.height = `${individualUploadLeftActive.scrollHeight}px`;

      renderUpload()

      const topOffset = 70; 
      const upload: HTMLDivElement = document.querySelector(".individual-upload__wrapper--active .individual-upload__left");

      const elementTop = upload.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = elementTop - topOffset;
  
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    })
  }

  individualAuthButton.addEventListener("click", () => {
    resetIndividualAuthCards()
  })

  individualAuthDocumentTypes.forEach((card: Element, i: number) => {
    card.addEventListener("click", () => {
      showActiveIndividualProof(card, i)
    })
  })

  individualAuthDocumentTypes.forEach((card: Element, i: number) => {
    card.addEventListener("keydown", e => {
      const keyboardEvent = <KeyboardEvent> e;

      if(keyboardEvent.key === "Enter"){
        showActiveIndividualProof(card, i)
      }
    })
  })

  individualAuthProofDocs.forEach((card: HTMLDivElement, i: number) => {
    card.addEventListener("click", () => {
      handleProofDocClick(card)
    })

    card.addEventListener("keydown", e => {
      const keyboardEvent = <KeyboardEvent> e;

      if(keyboardEvent.key === "Enter"){
        handleProofDocClick(card)
      }
    })
  })
}