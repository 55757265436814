const registerAccountType = document.querySelector(".register__wrapper") as HTMLDivElement;
const registerAccountTypeLink = document.querySelector(".register__link") as HTMLLinkElement;

if (registerAccountType) {
  const registerAccountTypeCards: NodeListOf<HTMLDivElement> = document.querySelectorAll(".register__card");

  const resetRegisterCards = () => {
    registerAccountTypeCards.forEach(card => {
      card.classList.remove("register__card--active")
    })
  }

  const setActiveCard = (item: HTMLDivElement) => {
    resetRegisterCards()
    item.classList.add("register__card--active")
    registerAccountTypeLink.href=item.getAttribute("data-link")
  }

  if(registerAccountTypeCards.length > 0) {
    const firstCardLink = registerAccountTypeCards[0].getAttribute("data-link");
    registerAccountTypeCards[0].classList.add("register__card--active");
    registerAccountTypeLink.href= firstCardLink
  
    registerAccountTypeCards.forEach((card: HTMLDivElement) => {
      card.addEventListener("click", () => {
        setActiveCard(card)
      })
  
      card.addEventListener("keydown", e => {
          const keyboardEvent = <KeyboardEvent> e;
  
          if(keyboardEvent.key === "Enter"){
            setActiveCard(card)
          }
      })
    })
  }
}