import Glide from '@glidejs/glide';
import clickAndDrag from '../../../helpers/clickDrag';

const dashboardUnverified = document.querySelector('.dashboard-unverified') as HTMLDivElement;

if (dashboardUnverified) {
    const closeButtons: NodeListOf<HTMLButtonElement> =
        dashboardUnverified.querySelectorAll('.recipient-detail__close');
    const contactsCarousel = dashboardUnverified.querySelector('.dashboard-unverified__carousel') as HTMLDivElement;
    const contactsList = dashboardUnverified.querySelector(
        '.dashboard-unverified__contacts-list',
    ) as HTMLUListElement;

    const handleOverlay = (card: Element, index: number, overlays: NodeListOf<HTMLDivElement>) => {
        let activeIndex = index;

        card.addEventListener('click', () => {
            overlays[activeIndex].classList.add('recipient-detail__overlay--active');
        });

        card.addEventListener('keydown', (e) => {
            const keyboardEvent = <KeyboardEvent>e;

            if (keyboardEvent.key === 'Enter') {
                overlays[activeIndex].classList.add('recipient-detail__overlay--active');
            }
        });

        if (overlays[activeIndex]) {
            overlays[activeIndex].addEventListener('click', (e) => {
                const targetElement = e.target as HTMLElement;

                if (targetElement.classList.contains('recipient-detail__overlay')) {
                    overlays[activeIndex].classList.remove('recipient-detail__overlay--active');
                }
            });
        }
    };

    closeButtons.forEach((button) => {
        button.addEventListener('click', () => {
            button.parentElement.parentElement.parentElement.parentElement.classList.remove(
                'recipient-detail__overlay--active',
            );
        });
    });

    if (contactsCarousel) {
        const contactsCarouselCards: NodeListOf<HTMLDivElement> =
            contactsCarousel.querySelectorAll('.dashboard-contact');
        const contactsCarouselOverlays: NodeListOf<HTMLDivElement> =
            contactsCarousel.querySelectorAll('.recipient-detail__overlay');

        const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
            const slidesLength = arr.length as number;
            let rangeMax = '0' as string;

            rangeMax = (slidesLength - 1).toString();

            input.max = rangeMax;

            setRangeThumbWidth(rangeMax, input);
        };

        const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
            const width = 94 / +max;
            input.style.setProperty('--thumb-size', width.toString() + 'px');

            if (max === '1') {
                input.style.setProperty('--thumb-size', '47px');
            }
        };

        contactsCarouselCards.forEach((card, index: number) => {
            handleOverlay(card, index, contactsCarouselOverlays);
        });

        const glide = new Glide(contactsCarousel, {
            type: 'slider',
            perView: 1.3,
            gap: 11,
            rewind: false,
            bound: true,
        });

        glide.mount();

        const carouselRange = dashboardUnverified.querySelector('.dashboard-unverified__range') as HTMLInputElement;
        const slides: NodeListOf<Element> = contactsCarousel.querySelectorAll('.dashboard-contact');

        carouselRange.addEventListener('input', () => {
            setTimeout(() => {
                glide.go(`=${carouselRange.value}`);
            }, 500);
        });

        glide.on('move.after', () => {
            const currentIndex = glide.index as string;
            carouselRange.value = currentIndex;
        });

        setRangeMax(slides, carouselRange);
    }

    if (contactsList) {
        const contactCards: NodeListOf<HTMLDivElement> = contactsList.querySelectorAll('.dashboard-contact');
        const contactCardsOverlays: NodeListOf<HTMLDivElement> =
            contactsList.querySelectorAll('.recipient-detail__overlay');
        contactCards.forEach((card, index: number) => {
            handleOverlay(card, index, contactCardsOverlays);
        });
    }

    clickAndDrag('.dashboard-unverified__links');
}
