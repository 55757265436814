const keyFactCardTitles = document.querySelectorAll('.key-facts__card-title') as NodeListOf<HTMLElement>;
const numberRegex = /\d+\.?\d*/;

keyFactCardTitles.forEach((title) => {
    const match = title.innerText.match(numberRegex);

    if (!match) return;

    let targetCount = parseFloat(match[0]);
    let currentCount = 0;
    let increment = targetCount < 10 ? 0.1 : 10;
    let isCountUpComplete = false;
    let isCountUpRunning = false;

    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isCountUpComplete && !isCountUpRunning) {
            isCountUpRunning = true;
            const interval = setInterval(() => {
                if (targetCount === 0) {
                    currentCount = targetCount;
                }else {
                    currentCount = +(currentCount + increment).toFixed(1);
                }
                title.innerText = title.innerText.replace(numberRegex, currentCount.toString());
                if (currentCount >= targetCount) {
                    clearInterval(interval);
                    isCountUpComplete = true;
                    isCountUpRunning = false;
                }
            }, 100);
        }
    });

    observer.observe(title);
});