const orgProfileConfirmComponent = document.querySelector(".org-profile-confirm__wrapper") as HTMLDivElement;

if (orgProfileConfirmComponent) {
  const directors: NodeListOf<HTMLDivElement> = orgProfileConfirmComponent.querySelectorAll(".org-profile-confirm__card");
  const continueButton: HTMLButtonElement = orgProfileConfirmComponent.querySelector(".org-profile-confirm__next");
  const selectDirectors: HTMLDivElement = orgProfileConfirmComponent.querySelector(".org-profile-confirm__select");
  const selectedNumber: HTMLSpanElement = orgProfileConfirmComponent.querySelector(".org-profile-confirm__number");
  const cardsContainer: HTMLDivElement = orgProfileConfirmComponent.querySelector(".org-profile-confirm__cards");

  const maxDirectors: number = 3;
  const moreThanThree = directors.length > maxDirectors;
  const selectedItems = [];
  const selectedNames: string[] = [];

  const director0: HTMLInputElement = document.querySelector('input[name="Director0"]');
  const director1: HTMLInputElement = document.querySelector('input[name="Director1"]');
  const director2: HTMLInputElement = document.querySelector('input[name="Director2"]');

  const handleItems = (enableAll = false) => {
    if(enableAll) {
      directors.forEach(item => {
        item.style.pointerEvents = "all";
        item.setAttribute("tabindex", "0");
      })
    } else if (!enableAll) {
      directors.forEach(item => {
        const radio = item.querySelector(".org-profile-confirm__card-radio") as HTMLInputElement;
  
        if(!radio.checked) {
          item.style.pointerEvents = "none";
          item.setAttribute("tabindex", "-1");
        }
      })
    }
  }

  const handleDirectorClick = (item: HTMLDivElement, index: number) => {
    const radio = item.querySelector(".org-profile-confirm__card-radio") as HTMLInputElement;
    const name = item.querySelector(".org-profile-confirm__card-title").textContent.trim();

      if (selectedItems.length < maxDirectors && !radio.checked) {
        radio.checked = true;
        selectedItems.push(index);
        selectedNames.push(name);

        if (selectedNames.length > 0) director0.value = selectedNames[0];
        if (selectedNames.length > 1) director1.value = selectedNames[1];
        if (selectedNames.length > 2) director2.value = selectedNames[2];

      } else if (radio.checked) {
        radio.checked = false;
        selectedItems.splice(selectedItems.indexOf(index), 1);
        selectedNames.splice(selectedNames.indexOf(name), 1);
      } 
  
      if(selectedItems.length > (maxDirectors - 1)) {
        continueButton.disabled = false;
        handleItems();

      } else {
        continueButton.disabled = true;
        handleItems(true);
      }

      selectedNumber.textContent = selectedItems.length.toString() + "/" + maxDirectors.toString();
  
      if(selectedItems.length === maxDirectors) {
        continueButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

  const showMoreThanThreeContent = () => {
    cardsContainer.classList.add("org-profile-confirm__cards-select");
    selectDirectors.classList.add("org-profile-confirm__select--active");
  }

  if (moreThanThree) {
    continueButton.disabled = true;

    showMoreThanThreeContent();

    directors.forEach((item: HTMLInputElement, index: number) => {
      item.classList.add("org-profile-confirm__card-alt");
      
      item.addEventListener("click", () => {
        handleDirectorClick(item, index);
      })

      item.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
            handleDirectorClick(item, index);
        }
      });
    });
  }
}