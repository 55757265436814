import axios from "axios";
import { NewRecipientPayload, NewRecipientResponse } from "./newRecipient.types";

const newRecipientComponent = document.querySelector('.new-recipient__wrapper') as HTMLDivElement;

if (newRecipientComponent) {
    const forms: NodeListOf<HTMLFormElement> = newRecipientComponent.querySelectorAll('.new-recipient__form');
    const inputs: NodeListOf<HTMLInputElement> = newRecipientComponent.querySelectorAll('.new-recipient__input');
    const button = newRecipientComponent.querySelector('.new-recipient__button') as HTMLButtonElement;
    const loader = newRecipientComponent.querySelector('.loader') as HTMLDivElement;
    const validStatus = newRecipientComponent.querySelector('.new-recipient__status--valid') as HTMLParagraphElement;
    const invalidStatus = newRecipientComponent.querySelector(
        '.new-recipient__status--invalid',
    ) as HTMLParagraphElement;

    //API variables
    let isLoading = false;
    let payload: NewRecipientPayload = {
        CardNumber: "",
        Cvv: ""
    };
    let response: NewRecipientResponse;

    const reset = () => {
        inputs.forEach((input) => {
            input.classList.remove('new-recipient__input--active');
            input.classList.remove('new-recipient__input--error');
        });
        validStatus.classList.remove('new-recipient__status--active');
        invalidStatus.classList.remove('new-recipient__status--active');
        button.disabled = true;
    };

    const handleValid = () => {
        inputs.forEach((input) => input.classList.add('new-recipient__input--active'));
        validStatus.classList.add('new-recipient__status--active');
        button.disabled = false;
        return true;
    };

    const handleInvalid = () => {
        inputs.forEach((input) => input.classList.add('new-recipient__input--error'));
        invalidStatus.classList.add('new-recipient__status--active');
        button.disabled = true;
        return false;
    };

    const showLoader = () => {
        inputs.forEach((input) => (input.disabled = true));
        loader.classList.add('loader--active');
    };

    const removeLoader = () => {
        inputs.forEach((input) => (input.disabled = false));
        loader.classList.remove('loader--active');
    };

    const getCardPayload = (): NewRecipientPayload => {
        const cardNumberInputs = newRecipientComponent.querySelectorAll('.new-recipient__input--card-number') as NodeListOf<HTMLInputElement>;
        const cvvInputs = newRecipientComponent.querySelectorAll('.new-recipient__input--card-cvv') as NodeListOf<HTMLInputElement>;
        const cardNumber = Array.from(cardNumberInputs).map((input) => input.value).join('');
        const cvv = Array.from(cvvInputs).map((input) => input.value).join('');

        return {
            CardNumber: cardNumber,
            Cvv: cvv
        };
    }

    const updateUI = () => {
        if (isLoading) {
            showLoader();
        } else {
            removeLoader();

            if (response?.success) {
                handleValid();
                button.disabled = true;

                setTimeout(() => {
                    window.location.href = response.data.url;
                }, 2500)
            } else {
                handleInvalid();
            }
        }
    }

    const fetchCard = async () => {
        if (isLoading) return;

        isLoading = true;
        updateUI();

        try {
            payload = getCardPayload();

            //For development:
            // const request = await axios.post<NewRecipientResponse>('https://localhost:44323/api/recipients/getRecipient', payload);

            const request = await axios.post<NewRecipientResponse>('/api/recipients/getRecipient', payload);

            response = request.data;
            isLoading = false;
        }
        catch (error) {
            console.error(error);
        }
        finally {
            updateUI();
        }
    }

    const checkInputs = async () => {
        reset();

        for (const input of Array.from(inputs)) {
            if (!input.value.trim()) {
                button.disabled = true;
                return false;
            }
        }

        await fetchCard();
    };

    forms.forEach((form) => {
        const codeInputs: NodeListOf<HTMLInputElement> = form.querySelectorAll('.new-recipient__input');

        codeInputs.forEach((input: HTMLInputElement, index: number) => {
            input.addEventListener('input', (event) => {
                const target = event.target as HTMLInputElement;
                let value = target.value;

                value = value.replace(/\D/g, '');

                if (value.length > 1) {
                    value = value.slice(0, 1);
                }

                target.value = value;

                if (index < codeInputs.length - 1 && value) {
                    (codeInputs[index + 1] as HTMLInputElement).focus();
                }

                checkInputs();
            });

            input.addEventListener('paste', async (event) => {
                event.preventDefault();

                const clipboardText = await navigator.clipboard.readText();
                const digits = clipboardText.match(/\d/g);

                if (digits) {
                    digits.forEach((digit, i) => {
                        if (i < codeInputs.length) {
                            (codeInputs[i] as HTMLInputElement).value = digit;
                            checkInputs();
                        }
                    });
                }
            });

            input.addEventListener('keydown', (event: KeyboardEvent) => {
                const target = event.target as HTMLInputElement;
                const key = event.key;

                if (key === 'Backspace' && index > 0) {
                    if (!target.value && index > 0) {
                        (codeInputs[index - 1] as HTMLInputElement).focus();
                    }
                }
            });
        });
    });
}
