/* eslint-disable */
import axios from "axios";
import { BankTransferResponse, CreatePaymentSession, FromCurrencyDto, PayoutMethod, ToCurrencyDto, TransferFeesDto } from "./createPayment.types";

const createPayComponent = document.querySelector(".create-pay__wrapper") as HTMLDivElement;
const transferSummary = document.querySelector(".transfer-summary__wrapper") as HTMLDivElement;

const closeButtons = document.querySelector(".change-currency__close") as HTMLDivElement;
const overlay = document.querySelector(".change-currency__overlay") as HTMLDivElement;
const changeCurrencyButton = document.querySelector(".create-pay__change-currency") as HTMLDivElement;
const saveCurrencyButton = document.querySelector(".create-pay__currencies-save") as HTMLDivElement;

if (createPayComponent && transferSummary) {
  // HTML Elements
  const thresholdErrorExceed = createPayComponent.querySelector(".create-pay__error-exceeded") as HTMLParagraphElement;
  const thresholdErrorMin = createPayComponent.querySelector(".create-pay__error-min") as HTMLParagraphElement;
  const minAmountParagraph = createPayComponent.querySelector(".create-pay__min-amount") as HTMLParagraphElement;
  const amountInput = createPayComponent.querySelector(".create-pay__amount") as HTMLInputElement;
  const currencySelect = createPayComponent.querySelector(".create-pay__currencies") as HTMLSelectElement;
  const currencySymbolElement = createPayComponent.querySelector(".create-pay__currency-symbol") as HTMLParagraphElement;
  const nextButton = createPayComponent.querySelector(".create-pay__button") as HTMLButtonElement;
  const payBankButton = document.querySelector(".create-pay__button--bank") as HTMLButtonElement;
  const backButton = transferSummary.querySelector(".transfer-summary__back") as HTMLButtonElement;
  const transferSummariesRight = transferSummary.querySelectorAll(".transfer-summary__right") as NodeListOf<HTMLDivElement>;
  const transferSummaryLeft = transferSummary.querySelector(".transfer-summary__left") as HTMLDivElement;
  let transferPurposeSelect = createPayComponent.querySelector("#create-pay__dropdown-select") as HTMLSelectElement;
  const uid = new URLSearchParams(window.location.search).get("_uid");
  const createPaymentDetailsContainer = document.querySelector(".create-pay__details") as HTMLDivElement;
  const createPaymentError = document.querySelector(".create-pay__error") as HTMLDivElement;
  const createPayAmount = document.getElementById("create-pay__amount") as HTMLInputElement;
  const createPayRecievedAmount = document.getElementById("create-pay__amount-received") as HTMLInputElement;
  const paymentMethodRadios = document.querySelectorAll("input[name='payment-method']") as NodeListOf<HTMLInputElement>;
  const bankPaymentMethod = Array.from(paymentMethodRadios).find((radio) => radio.value === "BankTransfer") as HTMLInputElement || undefined;
  let paymentMethodRadio = document.querySelector("input[name='payment-method']:checked") as HTMLInputElement

  // Currency Conversions
  const { ratesJson } = createPayComponent.dataset;
  const { feesJson } = createPayComponent.dataset;

  // Labels
  const receiveLabels = document.querySelectorAll(".values--receive-value") as NodeListOf<HTMLParagraphElement>;
  const sendLabels = document.querySelectorAll(".values--send-value") as NodeListOf<HTMLParagraphElement>;
  const exchangeRateLabels = document.querySelectorAll(".values--exchange-rate") as NodeListOf<HTMLParagraphElement>;
  const transferPurposeLabels = document.querySelectorAll(".values--transfer-purpose") as NodeListOf<HTMLParagraphElement>;
  const payValueLabels = document.querySelectorAll(".values--pay-value") as NodeListOf<HTMLParagraphElement>;
  const transferFees = document.querySelectorAll(".values--fees") as NodeListOf<HTMLParagraphElement>;
  const thresholdLabel = document.querySelector("#thresholdAmount") as HTMLSpanElement;
  const payoutCountryLabel = document.querySelector(".values--payout-country") as HTMLParagraphElement;
  const youreSendingLabel = document.querySelector(".value--youre-sending-value") as HTMLParagraphElement;
  const paymentMethodLabels = document.querySelectorAll(".values--payment-method") as NodeListOf<HTMLParagraphElement>;

  // ToolTip
  const tooltipIcon = document.querySelector('.create-pay__icon');
  const tooltipText = tooltipIcon.nextElementSibling as HTMLElement;

  let receiveCurrencyName = (createPayComponent.querySelector(".payload--receive-currency-name") as HTMLParagraphElement).innerText;
  let db: FromCurrencyDto[] = JSON.parse(ratesJson);
  let feesDb: TransferFeesDto = JSON.parse(feesJson);
  let isLoading: boolean = false;

  // Session variables
  let sendCurrency = db[0];
  let receiveCurrency: ToCurrencyDto = sendCurrency.ToCurrencies.find((currency) => currency.Name === receiveCurrencyName);
  let session: CreatePaymentSession;
  let currentPayoutMethod: PayoutMethod = paymentMethodRadio.value as PayoutMethod;

  const formatNumberWithCommas = (value: string) => {
    let number = value.replace(/[^\d.]/g, '');

    let [integerPart, decimalPart] = number.split('.');

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimalPart !== undefined) {
      decimalPart = decimalPart.replace(/\./g, '');
      return integerPart + '.' + decimalPart;
    }

    return integerPart;
  }

  const closeOverlays = () => {
    overlay.classList.remove("change-currency__overlay--active")
  }

  const showTransferSummary = () => {
    createPayComponent.classList.remove("create-pay__wrapper--active")
    transferSummary.classList.add("transfer-summary__wrapper--active")

    if (window.innerWidth > 768) {
      document.querySelector(`.transfer-summary__right[data-type='${currentPayoutMethod}']`)?.classList.add("transfer-summary__right--active");
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const hideTransferSummary = () => {
    const anyContainsActive = Array.from(transferSummariesRight).some((div) => div.classList.contains("transfer-summary__right--active"));
    transferSummariesRight.forEach((div) => {
      div.classList.remove("transfer-summary__right--active");
    });

    if (window.innerWidth < 768 && anyContainsActive) {
      transferSummaryLeft.classList.add("transfer-summary__left--active")
    } else {
      transferSummary.classList.remove("transfer-summary__wrapper--active")
      createPayComponent.classList.add("create-pay__wrapper--active")
    }
  }

  const resetErrors = () => {
    nextButton.disabled = false;
    thresholdErrorExceed?.classList.remove("create-pay__error--active")
    thresholdErrorMin?.classList.remove("create-pay__error--active")
  }

  const saveCurrency = () => {
    let selectedOption = currencySelect.options[currencySelect.selectedIndex];

    let customDataValue = selectedOption.getAttribute('data-cur-symbol') as string;

    currencySymbolElement.textContent = customDataValue;

    resetErrors()
    handleValueChange();
    updateMinAmount();
    handleThreshold(amountInput);
  }
  
  const initiateBankTransfer = async () => {
    if (isLoading) return;

    isLoading = true;

    try {
      // For development:
      // const response = await axios.post<BankTransferResponse>("https://localhost:44323/api/payments/initiateBankTransfer", session);
      
      const response = await axios.post<BankTransferResponse>("/api/payments/initiateBankTransfer", session);
      const { data } = response;

      window.location.href = data.data.url;
    }
    catch (error) {
      alert("An error occurred while processing your request. Please try again later.");
      console.error(error);
    }
    finally {
      isLoading = false;
    }
  }

  const hookListeners = () => {
    amountInput.addEventListener("input", (e: Event) => {
      const target = e.target as HTMLInputElement;
      amountInput.value = formatNumberWithCommas(target.value)

      resetErrors()
      handleThreshold(target);
      handleValueChange();
    })

    createPayRecievedAmount.addEventListener("input", (e: Event) => {
      const target = e.target as HTMLInputElement;
      createPayRecievedAmount.value = formatNumberWithCommas(target.value)

      resetErrors()
      handleThresholdRecieve(target);
      handleValueChange();
    })

    nextButton.addEventListener("click", () => {
      showTransferSummary()
    })

    backButton.addEventListener("click", () => {
      hideTransferSummary()
    })

    paymentMethodRadios.forEach((radio) => {
      radio.addEventListener("change", (e: Event) => {
        const target = e.target as HTMLInputElement;
        currentPayoutMethod = target.value as PayoutMethod;
        updatePaymentMethodElements();
        handleValueChange();
      })
    })

    payBankButton?.addEventListener("click", async () => {
      initiateBankTransfer();
    })

    transferPurposeSelect.addEventListener("change", handleValueChange)
  }

  const handleThreshold = (target?: HTMLInputElement) => {
    if (thresholdLabel && !isNaN(sendCurrency.Threshold)) {
      const text = formatNumberWithSymbol(sendCurrency.Threshold, 2, sendCurrency.Symbol);

      thresholdLabel.innerText = sendCurrency.Symbol + formatNumberWithCommas(text)

      if (target) {
        const originalSendAmountValue = parseFloat(target.value.replace(/[^\d.]/g, ''));

        if (originalSendAmountValue > sendCurrency.Threshold) {
          amountInput.value = formatNumberWithCommas(sendCurrency.Threshold.toString());
          thresholdErrorExceed?.classList.add("create-pay__error--active")
          handleValueChange()
        }
      }
    }
  }

  const handleThresholdRecieve = (target?: HTMLInputElement) => {
    if (thresholdLabel && !isNaN(sendCurrency.Threshold)) {
      const text = formatNumberWithSymbol(sendCurrency.Threshold, 2, sendCurrency.Symbol);

      thresholdLabel.innerText = sendCurrency.Symbol + formatNumberWithCommas(text)

      if (target) {
        const originalRecieveAmountValue = parseFloat(target.value.replace(/[^\d.]/g, ''));
        let recieveAmountThreshold = sendCurrency.Threshold * receiveCurrency.ExchangeRate;

        if (originalRecieveAmountValue > recieveAmountThreshold) {
          createPayRecievedAmount.value = formatNumberWithCommas(recieveAmountThreshold.toFixed(2));
          thresholdErrorExceed?.classList.add("create-pay__error--active")
          handleValueChange()
        }
      }
    }
  }

  const validateTotalToPay = () => {
    let totalToPayValue = parseFloat(sendLabels[0].textContent.replace(/[^\d.]/g, ''));
    let transferFeesValue = parseFloat(transferFees[0].textContent.replace(/[^\d.]/g, ''));

    let difference = Math.abs(totalToPayValue - transferFeesValue);

    if (difference < sendCurrency.MinAmount) {
      nextButton.disabled = true;
    } else if (difference >= sendCurrency.MinAmount) {
      nextButton.disabled = false;
    }
  }

  const handleInvalidExchangeRate = () => {
    amountInput.value = "";
    createPaymentDetailsContainer.classList.add("create-pay__details--disabled");
    createPaymentError.classList.add("create-pay__error--active");
    amountInput.disabled = true;
    nextButton.disabled = true;
    transferPurposeSelect.disabled = true;

    updateLabels("N/A", "N/A", "N/A", "N/A")
  }

  const handleValidExchangeRate = () => {
    amountInput.placeholder = "";
    createPaymentDetailsContainer.classList.remove("create-pay__details--disabled");
    createPaymentError.classList.remove("create-pay__error--active");
    amountInput.disabled = false;
    nextButton.disabled = false;
    transferPurposeSelect.disabled = false;

    updateLabels("", "", "", "");
  }

  const handleInvalidAmount = () => {
    nextButton.disabled = true;
    updateLabels("", "", transferPurposeSelect.value);
  }

  const handleValidAmount = () => {
    nextButton.disabled = false;

    const minValue: number = sendCurrency.MinAmount

    if (+amountInput.value < minValue) {
      nextButton.disabled = true;
      debouncedFunc(minValue)
    } else {
      updateSession();
    }
  }

  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;

    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debouncedFunc = debounce((min: number) => {
    if (+amountInput.value < min) {
      thresholdErrorMin.classList.add("create-pay__error--active");
    }
    updateSession();
  }, 1000);


  const calculateSendAmount = (amount: number, exchangeRate: number, fixedPoints: number = 5, symbol?: string): string => {
    const sendAmount = amount * exchangeRate;

    return formatNumberWithSymbol(sendAmount, fixedPoints, symbol);
  }

  const formatNumberWithSymbol = (value: number, fixedPoints: number = 5, symbol?: string): string => {
    if (!symbol) return value.toFixed(fixedPoints);
    if (isNaN(value)) return "";

    return `${symbol}${value.toFixed(fixedPoints)}`;
  }

  const calculateFees = (currentAmount: number): number => {
    return calculateFeeRate(currentAmount) + calculateFlatFee();
  }

  const calculateFeeRate = (currentAmount: number): number => {
    const feeRate = feesDb?.[currentPayoutMethod]?.FeeRate?.Value;

    if (feeRate) {
      const fee = currentAmount * feeRate;
      const roundedFee = Math.floor(fee * 100) / 100;
      return roundedFee;
    }

    return 0;
  }

  const calculateFlatFee = (): number => {
    const flatFee = feesDb?.[currentPayoutMethod]?.FlatFees[sendCurrency.Name]?.Value;

    return flatFee ?? 0;
  }

  const updatePaymentMethodElements = () => {
    paymentMethodRadio = document.querySelector("input[name='payment-method']:checked") as HTMLInputElement;

    if (bankPaymentMethod) {
      const topParent = bankPaymentMethod.parentElement?.parentElement as HTMLDivElement;

      if (!sendCurrency.HasBankDetails) {
        topParent?.classList.add("hidden-element");
  
        // Tick the first radio button that is not BankTransfer
        // when the BankTransfer radio button is checked
        if (paymentMethodRadio?.value === "BankTransfer") {
          paymentMethodRadio = Array.from(paymentMethodRadios).find((radio) => radio.value !== "BankTransfer") as HTMLInputElement;
          paymentMethodRadio.checked = true;
        }
      } else {
        topParent?.classList.remove("hidden-element");
      }

      currentPayoutMethod = paymentMethodRadio.value as PayoutMethod;
    }

    const parent = paymentMethodRadio?.parentElement as HTMLLabelElement || undefined;

    paymentMethodLabels.forEach((label) => label.textContent = parent.querySelector(".create-pay__item-title")?.textContent ?? "");
  }

  const updateLabels = (receiveAmount: string, sendAmount: string, tranferPurpose?: string, exchangeText?: string) => {
    updatePaymentMethodElements();
    let originalSendAmountValue = parseFloat(sendAmount.replace(/[^\d.]/g, ''));
    paymentMethodRadio = document.querySelector("input[name='payment-method']:checked") as HTMLInputElement;
    const fees = calculateFees(originalSendAmountValue);

    const sendAmountWithoutFees = formatNumberWithSymbol(originalSendAmountValue, 2, sendCurrency.Symbol);
    const sendAmountValue = originalSendAmountValue + fees;
    if (isNaN(sendAmountValue)) return;

    sendAmount = formatNumberWithSymbol(sendAmountValue, 2, sendCurrency.Symbol);

    receiveLabels.forEach((label) => label.textContent = receiveCurrency.Symbol + formatNumberWithCommas(receiveAmount));
    sendLabels.forEach((label) => label.textContent = sendCurrency.Symbol + formatNumberWithCommas(sendAmount));
    payValueLabels.forEach((label) => label.textContent = `Pay ${sendCurrency.Symbol + formatNumberWithCommas(sendAmount)}`);
    transferFees.forEach((label) => label.textContent = sendCurrency.Symbol + formatNumberWithCommas(fees.toFixed(2).toString()));

    if (tranferPurpose) transferPurposeLabels.forEach((label) => label.textContent = tranferPurpose);
    else updateTransferPurposeLabels();

    if (exchangeText) exchangeRateLabels.forEach((label) => label.textContent = exchangeText);
    else updateExchangeRateLabels();

    if (youreSendingLabel)
      youreSendingLabel.textContent = sendCurrency.Symbol + formatNumberWithCommas(sendAmountWithoutFees);

  }

  const updateExchangeRateLabels = () => {
    if (sendCurrency && receiveCurrency) {
      exchangeRateLabels.forEach((label) => {
        label.textContent = `${sendCurrency.Symbol} 1 = ${receiveCurrency.Symbol} ${receiveCurrency.ExchangeRate.toFixed(3)}`;
      })
    }
  }

  const updateTransferPurposeLabels = () => {
    transferPurposeLabels.forEach((label) => {
      label.textContent = transferPurposeSelect.value;
    })
  }

  const handleValueChange = () => {
    const originalSendValue = parseFloat(amountInput.value.replace(/[^\d.]/g, ''));
    sendCurrency = db[currencySelect.selectedIndex];
    receiveCurrency = sendCurrency.ToCurrencies.find((currency) => currency.Name === receiveCurrencyName) as ToCurrencyDto;
    handleThreshold();
    const recieveAmount = parseFloat(createPayRecievedAmount.value.replace(/[^\d.]/g, ''));

    if (!receiveCurrency) {
      handleInvalidExchangeRate();
      return;
    }
    handleValidExchangeRate();

    if (isNaN(originalSendValue)) {
      handleInvalidAmount();
      return;
    }

    handleValidAmount();
    transferPurposeSelect = createPayComponent.querySelector("#create-pay__dropdown-select") as HTMLSelectElement;
    const toReceiveAmount = formatNumberWithSymbol(recieveAmount, 2, receiveCurrency.Symbol);
    const toSendAmount = calculateSendAmount(originalSendValue, (1), 2, sendCurrency.Symbol);
    const transferPurpose = transferPurposeSelect.value;
    const exchangeRate = `${sendCurrency.Symbol} 1 = ${receiveCurrency.Symbol} ${receiveCurrency.ExchangeRate.toFixed(3)}`;

    updateLabels(toReceiveAmount, toSendAmount, transferPurpose, exchangeRate);

    validateTotalToPay();
  }

  const populateDropdowns = () => {
    currencySelect.innerHTML = "";

    db.forEach((currency) => {
      let option = document.createElement("option");
      option.value = currency.Name;
      option.textContent = currency.Name;
      option.setAttribute("data-cur-symbol", currency.Symbol);
      option.classList.add("create-pay__currency");
      currencySelect.appendChild(option);
    })

    currencySelect.selectedIndex = 0;
    currencySymbolElement.textContent = db[0].Symbol;
  }

  const updateMinAmount = () => {
    const amount = formatNumberWithSymbol(sendCurrency.MinAmount, 2, sendCurrency.Symbol);

    if (minAmountParagraph != null) {
      minAmountParagraph.textContent = sendCurrency.Symbol + formatNumberWithCommas(amount)
    }
  }

  // This bool is used in order to inhibit the chance of a infinite loop
  let isUserInput = true;

  const updateSenderInput = () => {
    const receivedAmountValue = parseFloat(createPayRecievedAmount.value.replace(/[^\d.]/g, ''));
    const senderAmount = (receivedAmountValue / receiveCurrency.ExchangeRate).toFixed(2);
    createPayAmount.value = formatNumberWithCommas(senderAmount);
    createPayAmount.dispatchEvent(new Event('input'));
  };

  const updateRecievesInput = () => {
    const amountValue = parseFloat(createPayAmount.value.replace(/[^\d.]/g, ''));
    const sendAmount = (amountValue * receiveCurrency.ExchangeRate).toFixed(2);
    createPayRecievedAmount.value = formatNumberWithCommas(sendAmount);
    createPayRecievedAmount.dispatchEvent(new Event('input'));
  };

  createPayAmount.addEventListener('input', () => {
    if (isUserInput) {
      isUserInput = false;
      updateRecievesInput();
      isUserInput = true;
    }
  });

  createPayRecievedAmount.addEventListener('input', () => {
    if (isUserInput) {
      isUserInput = false;
      updateSenderInput();
      isUserInput = true;
    }
  });

  const updateSession = () => {
    const amountValue = parseFloat(amountInput.value.replace(/[^\d.]/g, ''));
    const sendAmount = amountValue * receiveCurrency.ExchangeRate;

    session = {
      Uid: parseInt(uid),
      ExchangeRate: receiveCurrency.ExchangeRate,
      FromValue: amountValue,
      FromCurrency: sendCurrency.Name,
      FromSymbol: sendCurrency.Symbol,
      ToCurrency: receiveCurrency.Name,
      ToSymbol: receiveCurrency.Symbol,
      ToValue: sendAmount,
      PayoutCountry: payoutCountryLabel?.textContent ?? "",
      PayoutMethod: currentPayoutMethod,
      TransferPurpose: transferPurposeSelect.value
    };

    sessionStorage.setItem("createPaymentSession", JSON.stringify(session));
    dispatchSessionChangeEvent();

    function dispatchSessionChangeEvent() {
      const event = new Event('sessionChange');
      window.dispatchEvent(event);
    }
  }

  const showTooltip = function () {
    tooltipText.classList.add('create-pay__tooltip--active');
  }

  const hideTooltip = function () {
    tooltipText.classList.remove('create-pay__tooltip--active');
  }

  tooltipIcon.addEventListener('mouseover', showTooltip);
  tooltipIcon.addEventListener('mouseout', hideTooltip);
  tooltipIcon.addEventListener('click', function () {
    if (tooltipText.classList.contains('create-pay__tooltip--active')) {
      tooltipText.classList.remove('create-pay__tooltip--active');
    } else {
      tooltipText.classList.add('create-pay__tooltip--active');
    }
  });

  tooltipIcon.addEventListener('touchstart', function (e) {
    e.preventDefault();
    if (tooltipText.classList.contains('create-pay__tooltip--active')) {
      tooltipText.classList.remove('create-pay__tooltip--active');
    } else {
      tooltipText.classList.add('create-pay__tooltip--active');
    }
  });

  document.addEventListener('touchstart', function (e) {
    if (e.target !== tooltipIcon && e.target !== tooltipText) {
      hideTooltip();
    }
  })

  changeCurrencyButton.addEventListener("click", () => {
    changeCurrencyButton.nextElementSibling.classList.add("change-currency__overlay--active");
  })

  closeButtons.addEventListener("click", () => {
    closeOverlays()
  })

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeOverlays()
    }
  });

  overlay.addEventListener("click", (e: Event) => {
    const target = e.target as HTMLDivElement;

    if (target.classList.contains("change-currency__overlay--active"))
      closeOverlays();
  });

  saveCurrencyButton.addEventListener("click", () => {
    saveCurrency();
    closeOverlays();
  });

  const init = () => {
    populateDropdowns();
    handleValueChange();
    hookListeners();
    handleThreshold();
    updateMinAmount();
    updatePaymentMethodElements();
  }

  init();
}