document.addEventListener("DOMContentLoaded", function () {
  let rotatingElement: HTMLElement | null = document.querySelector('.contact-form__image');
  let contactForm: HTMLElement | null = document.querySelector('.contact-form');
  let lastScrollTop = 0;

  if (rotatingElement && contactForm) {
    contactForm.addEventListener('mouseover', function () {
      window.addEventListener('scroll', handleScroll);
    });
  }

  function handleScroll() {
    let scrollPos = window.scrollY;
    let rotation = (scrollPos - lastScrollTop) / 2; 
    let translateZ = Math.max(0, scrollPos / 30); 

    rotation = Math.min(Math.max(rotation, -20), 10);

    rotatingElement!.style.transform = `rotateY(${rotation}deg) rotateX(${rotation}deg) translateZ(${translateZ}px)`;
    lastScrollTop = scrollPos;
  }
});