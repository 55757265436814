const individualResetPassword = document.querySelector(".individual-reset-pass__form") as HTMLFormElement;

if (individualResetPassword) {
  const passwordInput = document.querySelector(".individual-reset-pass__password") as HTMLInputElement;
  const passwordAgainInput = document.querySelector(".individual-reset-pass__password-again") as HTMLInputElement;
  const passwordAgainValidation = document.querySelector(".individual-reset-pass__validation-password-again") as HTMLSpanElement;
  const criteriaItems = individualResetPassword.querySelectorAll(".individual-reset-pass__item") as NodeListOf<HTMLUListElement>;
  const showPasswordButtons = individualResetPassword.querySelectorAll(".zmdi-eye") as NodeListOf<Element>;
  const hidePasswordButtons = individualResetPassword.querySelectorAll(".zmdi-eye-off") as NodeListOf<Element>;

  //  validations are: 
  //  0: six characters or more
  //  1: one lowercase letter
  //  2: one uppercase letter 
  //  3: one number
  //  4: one symbol (!@#$&*)
  //  full regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*])[A-Za-z\d!@#$&*]{6,}$/
  let validations = [false, false, false, false, false];

  let formValidation = {
    password: false
  }

  let isSubmitClickedFirstTime = false

  const showValidationMessage = () => {
    if (isSubmitClickedFirstTime) {
      passwordAgainValidation.classList.add("individual-reset-pass__validation--active")
    }
  }

  const removeValidationMessage = () => {
    passwordAgainValidation.classList.remove("individual-reset-pass__validation--active")
  }

  const validatePassword = (password: string): string | boolean[] => {
    validations = [false, false, false, false, false];

    if (password.length > 5) {
      validations[0] = true;
    }

    if (/(?=.*[a-z])/.test(password)) {
      validations[1] = true;
    }

    if (/(?=.*[A-Z])/.test(password)) {
      validations[2] = true;
    }

    if (/(?=.*\d)/.test(password)) {
      validations[3] = true;
    }

    if (/(?=.*[!@#$&*])/.test(password)) {
      validations[4] = true;
    }

    let isPasswordValid = false;

    if (passwordInput.value === passwordAgainInput.value && validations.every(val => val === true)) {
      isPasswordValid = true
      removeValidationMessage()
    } else {
      showValidationMessage()
    }

    if (!isPasswordValid) {
      formValidation.password = false
    } else {
      formValidation.password = true
    }

    return isPasswordValid ? "Password is valid." : validations;
  }

  const resetCriteriaItems = () => {
    criteriaItems.forEach(item => {
      item.classList.remove("individual-reset-pass__item--active")
    })
  }

  showPasswordButtons.forEach(button => {
    button.addEventListener("click", () => {
      button.previousElementSibling.setAttribute("type", "text");
      button.classList.remove("zmdi-eye--active")
      button.nextElementSibling.classList.add("zmdi-eye-off--active")
    })
  })

  hidePasswordButtons.forEach(button => {
    button.addEventListener("click", () => {
      button.previousElementSibling.previousElementSibling.setAttribute("type", "password");
      button.classList.remove("zmdi-eye-off--active")
      button.previousElementSibling.classList.add("zmdi-eye--active")
    })
  })

  passwordInput.addEventListener("input", () => {
    validatePassword(passwordInput.value)

    for (let i = 0; i < criteriaItems.length; i++) {
      if (validations[i] === true) {
        criteriaItems[i].classList.add("individual-reset-pass__item--active")
      } else {
        criteriaItems[i].classList.remove("individual-reset-pass__item--active")
      }
    }
  })

  passwordAgainInput.addEventListener("input", () => {
    validatePassword(passwordAgainInput.value)
  })

  individualResetPassword.addEventListener("submit", async e => {

    isSubmitClickedFirstTime = true

    if (passwordInput.value !== passwordAgainInput.value) {
      showValidationMessage()
    }

    const formValues = {
      password: passwordInput.value,
    };

    if (formValidation.password === true) {
      console.log(formValues);

      validations = [false, false, false, false, false]
      formValidation = {
        password: false
      }

      resetCriteriaItems()

    } else {
      console.log("Form is NOT valid");
      e.preventDefault();
    }
  });
}