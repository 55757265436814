import Glide from '@glidejs/glide'
import { NewPaymentsContactsPayload, NewPaymentsContactsResponse } from './newPayments.types';
import axios from 'axios';

const newPaymentComponent = document.querySelector(".new-pay__wrapper") as HTMLDivElement;

if (newPaymentComponent) {
  const searchInput = newPaymentComponent.querySelector(".new-pay__form-desktop .new-pay__search") as HTMLInputElement;
  const searchInputMobile = newPaymentComponent.querySelector(".new-pay__form-mobile .new-pay__search") as HTMLInputElement;
  let newPaymentContacts = newPaymentComponent.querySelector(".new-pay__contacts-list") as HTMLUListElement;
  const newPaymentRecipients = newPaymentComponent.querySelector(".new-pay__recipients-list") as HTMLUListElement;
  const newPaymentCarousel = newPaymentComponent.querySelector(".new-pay__recipients-carousel") as HTMLDivElement;
  const newPayAmountText = newPaymentComponent.querySelector(".new-pay__number") as HTMLParagraphElement;
  const recipientCards: NodeListOf<Element> = newPaymentRecipients.querySelectorAll(".dashboard-contact");
  const carouselCards: NodeListOf<Element> = newPaymentCarousel.querySelectorAll(".dashboard-contact");
  const loadMoreButton = newPaymentComponent.querySelector(".new-pay__load") as HTMLButtonElement;
  const loader = newPaymentComponent.querySelector(".payments__loader") as HTMLLIElement;
  let contactDetails: NodeListOf<HTMLDivElement> = newPaymentComponent.querySelectorAll(".recipient-detail__overlay");

  //API Variables
  let db: NewPaymentsContactsResponse = {
    html: "",
    hasReachedEnd: false,
    lastId: 0
  };
  let isLoading = false;
  let payload: NewPaymentsContactsPayload = {
    keyword: "",
    lastId: 0,
    pageIncrement: 10
  };

  const handleOverlay = (card: Element, index: number, overlays) => {
    let activeIndex = index;

    card.addEventListener('click', () => {
        overlays[activeIndex].classList.add('recipient-detail__overlay--active');
    });

    card.addEventListener('keydown', (e) => {
        const keyboardEvent = <KeyboardEvent>e;

        if (keyboardEvent.key === 'Enter') {
            overlays[activeIndex].classList.add('recipient-detail__overlay--active');
        }
    });

    if (overlays[activeIndex]) {
        overlays[activeIndex].addEventListener('click', (e) => {
            const targetElement = e.target as HTMLElement;

            if (targetElement.classList.contains('recipient-detail__overlay')) {
                overlays[activeIndex].classList.remove('recipient-detail__overlay--active');
            }
        });
    }
};

  const initGlide = (carousel, carouselRange, slides) => {
    carouselRange.addEventListener("input", () => {
      setTimeout(() => {
        glide.go(`=${carouselRange.value}`)
      }, 500);
    })

    const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
      const slidesLength = arr.length as number;
      let rangeMax = '0' as string;

      rangeMax = (slidesLength - 1).toString();

      input.max = rangeMax;

      setRangeThumbWidth(rangeMax, input);
    }

    const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
      const width = 94 / +max
      input.style.setProperty('--thumb-size', width.toString() + "px");

      if (max === "1") {
        input.style.setProperty('--thumb-size', "47px");
      }
    }

    const glide = new Glide(carousel, {
      type: 'slider',
      perView: 1.37,
      gap: 11,
      rewind: false,
      bound: true,
    })

    glide.mount()

    glide.on('move.after', () => {
      const currentIndex = glide.index as string;
      carouselRange.value = currentIndex;
    })

    setRangeMax(slides, carouselRange)

    const carouselOverlays: NodeListOf<HTMLDivElement> =
    carousel.querySelectorAll('.recipient-detail__overlay');

    carouselCards.forEach((card, i: number) => {
      handleOverlay(card, i, carouselOverlays)
    })
  }

  const init = () => {
    const hookApiListeners = () => {
      let timeoutId: ReturnType<typeof setTimeout>;

      const handleSearch = (e: Event) => {
        const target = e.target as HTMLInputElement;
        const str = target.value;

        payload.keyword = str;
        payload.lastId = 0;
        db.html = "";

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            fetchContacts();
        }, 1000);
      }

      loadMoreButton.addEventListener("click", () => {
        payload.lastId = db.lastId;
        fetchContacts();
      })

      searchInput.addEventListener("input", handleSearch);
      searchInputMobile.addEventListener("input", handleSearch);
    }

    // init recipients carousel only
    const carousel = newPaymentComponent.querySelector(".new-pay__recipients-carousel") as HTMLUListElement;
    const carouselRange = newPaymentComponent.querySelector(".new-pay__range") as HTMLInputElement;
    const slides: NodeListOf<Element> = carousel.querySelectorAll(".dashboard-contact");

    hookApiListeners();
    initGlide(carousel, carouselRange, slides);
  }

  const hookOverlays = () => {
    const contactsCards: NodeListOf<Element> = newPaymentContacts.querySelectorAll(".dashboard-contact");
    const closeButtons: NodeListOf<HTMLButtonElement> = newPaymentComponent.querySelectorAll(".recipient-detail__close");

    const contactsOverlays: NodeListOf<HTMLDivElement> =
    newPaymentContacts.querySelectorAll('.recipient-detail__overlay');

    const recipientOverlays: NodeListOf<HTMLDivElement> =
    newPaymentRecipients.querySelectorAll('.recipient-detail__overlay');

    contactsCards.forEach((card, i: number) => {
      handleOverlay(card, i, contactsOverlays)
    })

    recipientCards.forEach((card, i: number) => {
      handleOverlay(card, i, recipientOverlays)
    })

    closeButtons.forEach(button => {
      button.addEventListener("click", () => {
        contactDetails.forEach(item => item.classList.remove("recipient-detail__overlay--active"))
        contactsCards.forEach(item => item.classList.remove("recipient-detail__overlay--active"))
      })
    })

    contactDetails.forEach(item => {
      item.addEventListener("keydown", e => {
        const keyboardEvent = <KeyboardEvent>e;

        if (keyboardEvent.key === "Escape") {
          item.classList.remove("recipient-detail__overlay--active");
        }
      })
    })
  }

  const clearData = () => {
    const paymentsChildren = Array.from(newPaymentContacts.children) as HTMLElement[];
    const itemsToKeep = [loadMoreButton.className, loader.className]

    paymentsChildren.forEach(child => {
      if (!itemsToKeep.includes(child.className)) {
        child.remove();
      }
    })
  }

  const refreshVariables = () => {
    newPaymentContacts = newPaymentComponent.querySelector(".new-pay__contacts-list") as HTMLUListElement;
    contactDetails = newPaymentComponent.querySelectorAll(".recipient-detail__overlay") as NodeListOf<HTMLDivElement>;
  }

  const updateUI = () => {
    refreshVariables();

    if (isLoading) {
      clearData();

      refreshVariables();
      loadMoreButton.classList.add("hidden");
      loader.classList.add("payments__loader--active");
      newPaymentContacts.querySelector(".payments__item-caption")?.remove();
      newPayAmountText.textContent = "(0)";
    } else {
      loader.classList.remove("payments__loader--active");

      if (!db.hasReachedEnd) {
        loadMoreButton.classList.remove("hidden");
      }

      if (db.html) {
        newPaymentContacts.insertAdjacentHTML("afterbegin", db.html);
      }

      if (db.hasReachedEnd) {
        newPaymentContacts.insertAdjacentHTML("beforeend",
        '<li class="payments__item-caption flex flex--align-center flex--justify-center">End of results</li>');
      } else {
        loadMoreButton.classList.remove("hidden");
      }

      refreshVariables();
      newPayAmountText.textContent = `(${newPaymentContacts.querySelectorAll(".dashboard-contact").length.toString()})`;
    }

    hookOverlays();
  }

  const fetchContacts = async () => {
    if (isLoading) return;

    isLoading = true;
    updateUI();

    try {
      // For development:
      // const response = (await axios.get<NewPaymentsContactsResponse>("https://localhost:44323/api/contacts/getAllContacts", {
      //   params: {
      //     ...payload
      //   },
      // })).data;

      const response = (await axios.get("/api/contacts/getAllContacts", {
        params: {
          ...payload
        },
      })).data;

      isLoading = false;

      db.html += response.html;
      db.hasReachedEnd = response.hasReachedEnd;
      db.lastId = response.lastId;
    } catch (error: any) {
      console.error(error);
    } finally {
      updateUI();
      hookOverlays();
      
      // init contacts carousel with new data
      const listElement = newPaymentComponent.querySelector(".new-pay__contacts-list")
      const cardDetails: NodeListOf<HTMLLIElement> = listElement.querySelectorAll(".recipient-detail")
      
      cardDetails.forEach(item => {
        const carousel = item.querySelector(".recipient-detail__carousel");
        const carouselRange = item.querySelector(".recipient-detail__carousel-range")
        const slides: NodeListOf<Element> = item.querySelectorAll(".recipient-detail__item");

        initGlide(carousel, carouselRange, slides);
      })      
    }
  }

  hookOverlays();
  init();
  fetchContacts();
}