const loginForm = document.querySelector(".login__form") as HTMLFormElement;

if (loginForm) {
  const resetLoginValidation = () => {
    loginForm.querySelectorAll(".login__validation--active").forEach((node) => {
      node.classList.remove("login__validation--active");
    })
  }

  const usernameInput = document.querySelector(".login__username") as HTMLInputElement;
  const passwordInput = document.querySelector(".login__password") as HTMLInputElement;
  const loginUsernameValidation = document.querySelector(".login__validation-username") as HTMLElement;
  const loginPasswordValidation = document.querySelector(".login__validation-password") as HTMLElement;

  usernameInput.addEventListener("input", () => {
    loginUsernameValidation.classList.remove("login__validation--active")
  })

  passwordInput.addEventListener("input", () => {
    loginPasswordValidation.classList.remove("login__validation--active")
  })

  loginForm.addEventListener("submit", async e => {
    resetLoginValidation();

    if(!usernameInput.value) {      
      loginUsernameValidation.classList.add("login__validation--active")
    }

    if(!passwordInput.value) {      
      loginPasswordValidation.classList.add("login__validation--active")
    }

    if (!(usernameInput.value && passwordInput.value)) {
      e.preventDefault();
    }
  });

  // TODO:
  // 1. Create a function that allows to enter a validation message for each validation element
  // based on the type of errors after submit
  // 2. Setup a POST request once API is ready
}

(() => {
  let rotatingElementLogin: HTMLElement | null = document.querySelector('.login__image');
  let lastScrollTop = 0;

  if (rotatingElementLogin) {
    rotatingElementLogin.addEventListener('mouseover', function () {
      window.addEventListener('scroll', handleLoginScroll);
    })
  }

  function handleLoginScroll() {
    let scrollPos = window.scrollY;
    let rotation = (scrollPos - lastScrollTop) / 2; 
    let translateZ = Math.max(0, scrollPos / 30); 

    rotation = Math.min(Math.max(rotation, -20), 10);

    rotatingElementLogin!.style.transform = `rotateY(${rotation}deg) rotateX(${rotation}deg) translateZ(${translateZ}px)`;
    lastScrollTop = scrollPos;
  }
})();