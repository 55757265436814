const signUpOrganisation = document.querySelector(".sign-in-details__form-organisation") as HTMLFormElement;

if (signUpOrganisation) {
  const emailInput = document.querySelector(".sign-in-details__email") as HTMLInputElement;
  const passwordInput = document.querySelector(".sign-in-details__password") as HTMLInputElement;
  const passwordAgainInput = document.querySelector(".sign-in-details__password-again") as HTMLInputElement;
  const passwordAgainValidation = document.querySelector(".sign-in-details__validation-password-again") as HTMLSpanElement;
  const signUpOrganisationEmailValidation = document.querySelector(".sign-in-details__validation-email") as HTMLElement;
  const criteriaItems = signUpOrganisation.querySelectorAll(".sign-in-details__item") as NodeListOf<HTMLUListElement>;
  const showPasswordButtons = signUpOrganisation.querySelectorAll(".zmdi-eye") as NodeListOf<Element>;
  const hidePasswordButtons = signUpOrganisation.querySelectorAll(".zmdi-eye-off") as NodeListOf<Element>;

  const regexPattern = signUpOrganisation.getAttribute("pattern") as string || null;

  //  validations are:
  //  0: six characters or more
  //  1: one lowercase letter
  //  2: one uppercase letter
  //  3: one number
  //  4: one symbol (!@#$&*)
  //  full regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*])[A-Za-z\d!@#$&*]{6,}$/

  let conditions = []
  let validations = [false]

  let formValidation = {
    email: false,
    password: false
  }

  var recaptcha = false;
  window.recaptcha = false;

  let isSubmitClickedFirstTime = false;

  // extract individual conditions from the regex pattern
  const extractConditions = (regexPattern: string) => {
    validations = [false];
    conditions = []

    conditions = regexPattern.match(/\(\?=(.*?)\)|\(\?!(.*?)\)|\(\?<=(.*?)\)|\(\?<(.*?)\)/g);

    conditions = conditions.filter(condition => condition !== null);

    // map each condition to a validation array
    conditions.map(condition => validations.push(false));
  };

  const showValidationMessage = () => {
    if (isSubmitClickedFirstTime) {
      passwordAgainValidation.classList.add("sign-in-details__validation--active")
    }
  }

  const removeValidationMessage = () => {
    passwordAgainValidation.classList.remove("sign-in-details__validation--active")
  }

  const validateEmail = (email: string) => {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      signUpOrganisationEmailValidation.classList.remove("sign-in-details__validation--active")
      formValidation.email = true
    } else {
      signUpOrganisationEmailValidation.classList.add("sign-in-details__validation--active")
      formValidation.email = false
    }
  };

  function validateRecaptcha () {
    window.recaptcha = true;
  }

  window.validateRecaptcha = validateRecaptcha;

  const validatePassword = (password: string): string | boolean[] => {
    extractConditions(regexPattern)

    if (password.length > 5) {
      validations[0] = true;
    }

    conditions.forEach((cond, index) => {
      if (new RegExp(cond).test(password)) {
        validations[index + 1] = true
      }
    })

    let isPasswordValid = false;

    if (passwordInput.value && validations.every(val => val === true)) {
      isPasswordValid = true
      removeValidationMessage()
      resetCriteriaItems()
    } else {
      showValidationMessage()
    }

    if (isPasswordValid) {
      formValidation.password = true
    } else {
      formValidation.password = false
    }

    return isPasswordValid ? "Password is valid." : validations;
  }

  const resetCriteriaItems = () => {
    criteriaItems.forEach(item => {
      item.classList.remove("sign-in-details__item--active")
    })
  }

  showPasswordButtons.forEach(button => {
    button.addEventListener("click", () => {
      button.previousElementSibling.setAttribute("type", "text");
      button.classList.remove("zmdi-eye--active")
      button.nextElementSibling.classList.add("zmdi-eye-off--active")
    })
  })

  hidePasswordButtons.forEach(button => {
    button.addEventListener("click", () => {
      button.previousElementSibling.previousElementSibling.setAttribute("type", "password");
      button.classList.remove("zmdi-eye-off--active")
      button.previousElementSibling.classList.add("zmdi-eye--active")
    })
  })

  emailInput.addEventListener("input", () => {
    if (isSubmitClickedFirstTime) {
      validateEmail(emailInput.value)
    }
  })

  passwordInput.addEventListener("input", () => {
    validatePassword(passwordInput.value)

    for (let i = 0; i < criteriaItems.length; i++) {
      if (validations[i] === true) {
        criteriaItems[i].classList.add("sign-in-details__item--active")
      } else {
        criteriaItems[i].classList.remove("sign-in-details__item--active")
      }
    }
  })

  passwordAgainInput.addEventListener("input", () => {
    removeValidationMessage()
  })

  signUpOrganisation.addEventListener("submit", async e => {
    isSubmitClickedFirstTime = true

    if (passwordInput.value !== passwordAgainInput.value) {
      showValidationMessage()
    }

    validateEmail(emailInput.value)

    const formValues = {
      email: emailInput.value,
      password: passwordInput.value,
    };

    if (formValidation.email === true 
      && formValidation.password === true 
      && passwordInput.value === passwordAgainInput.value
      && window.recaptcha === true) {
      formValidation = {
        email: false,
        password: false
      }

      resetCriteriaItems()
    } else {
      e.preventDefault();
      window.recaptcha = false;
    }
  });

  if (regexPattern) {
    extractConditions(regexPattern)
  }
}