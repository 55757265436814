import Glide from '@glidejs/glide';

const recipientDetails: NodeListOf<HTMLDivElement> = document.querySelectorAll(".recipient-detail");

if (recipientDetails.length > 0) {
  const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
    const slidesLength = arr.length;
    const rangeMax = (slidesLength - 2).toString();

    input.max = rangeMax;

    setRangeThumbWidth(rangeMax, input);
  };

  const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
    const width = 94 / +max;
    input.style.setProperty('--thumb-size', width.toString() + "px");

    if (max === "1") {
      input.style.setProperty('--thumb-size', "47px");
    }
  };

  recipientDetails.forEach((item) => {
    const glide = new Glide(item, {
      type: 'slider',
      perView: 2.8,
      gap: 10,
      rewind: true,
      bound: true,
    });

    glide.mount();

    const carouselRange = item.querySelector(".recipient-detail__carousel-range") as HTMLInputElement;
    const slides: NodeListOf<Element> = item.querySelectorAll(".recipient-detail__item");

    carouselRange.addEventListener("input", e => {
      setTimeout(() => {
        glide.go(`=${carouselRange.value}`);
      }, 500);
    });

    glide.on('move.after', () => {
      const currentIndex = glide.index;
      carouselRange.value = currentIndex.toString();
    });

    setRangeMax(slides, carouselRange);
  });
}
