const loginCountry = document.querySelector(".login-country") as HTMLDivElement | null;

if (loginCountry) {
    const countryItems = loginCountry.querySelectorAll(".login-country__item") as NodeListOf<HTMLDivElement>;
    const nextButton = loginCountry.querySelector(".button") as HTMLButtonElement;
    let activeCountry = document.querySelector(".login-country__item.active") as HTMLDivElement | null;
    let countryInput = document.getElementById('selectedCountry') as HTMLInputElement;
    
    const toggleCountry = (elm: HTMLDivElement) => {
        if (elm == activeCountry) return;
        
        activeCountry?.classList.remove("login-country__item--active");
        activeCountry = elm;
        activeCountry.classList.add("login-country__item--active");
        let activeCountryName = activeCountry.querySelector(".login-country__item-name")?.textContent || "";
        countryInput.value = activeCountryName.trim();
        nextButton.disabled = false;
    }

    const hookListeners = () => {
        countryItems.forEach((item) => {
            item.addEventListener("click", (e: Event) => {
                const target = (e.target as HTMLElement).closest(".login-country__item") as HTMLDivElement;

                toggleCountry(target);
            })
        })
    }

    document.addEventListener("DOMContentLoaded", hookListeners);
}