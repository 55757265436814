import Glide from '@glidejs/glide';
import clickAndDrag from '../../../helpers/clickDrag';

const dashboard = document.querySelector('.dashboard') as HTMLDivElement;

if (dashboard) {
    const closeButtons: NodeListOf<HTMLButtonElement> = dashboard.querySelectorAll('.recipient-detail__close');
    const dashboardRight = dashboard.querySelector(".dashboard__right") as HTMLDivElement;
    const footer = document.querySelector(".footer") as HTMLElement;

    const dashboardPaymentCarousels: NodeListOf<HTMLDivElement> =
        dashboard.querySelectorAll('.dashboard__carousel');
    const contactsCarousel = dashboard.querySelector('.dashboard__carousel-contacts') as HTMLDivElement;

    const cardsCarousel = dashboard.querySelector('.dashboard__carousel-cards') as HTMLDivElement;
    const dashboardCardsCarousel: NodeListOf<HTMLDivElement> = cardsCarousel.querySelectorAll('.dash-card');
    const dashboardCardsCarouselOverlays: NodeListOf<HTMLDivElement> =
        cardsCarousel.querySelectorAll('.recipient-detail__overlay');

    const contactsCarouselCards: NodeListOf<HTMLDivElement> =
        contactsCarousel.querySelectorAll('.dashboard-contact');
    const contactsCarouselOverlays: NodeListOf<HTMLDivElement> =
        contactsCarousel.querySelectorAll('.recipient-detail__overlay');

    const contactsList = dashboard.querySelector('.dashboard__contacts-list') as HTMLUListElement;
    const cardsList = dashboard.querySelector('.dashboard__cards') as HTMLDivElement;

    const contactCards: NodeListOf<HTMLDivElement> = contactsList.querySelectorAll('.dashboard-contact');
    const dashboardCards: NodeListOf<HTMLDivElement> = cardsList.querySelectorAll('.dash-card');

    const contactCardsOverlays: NodeListOf<HTMLDivElement> =
        contactsList.querySelectorAll('.recipient-detail__overlay');
    const dashboardCardsOverlays: NodeListOf<HTMLDivElement> =
        cardsList.querySelectorAll('.recipient-detail__overlay');

    const setRangeMax = (arr: NodeListOf<Element>, input: HTMLInputElement) => {
        const slidesLength = arr.length as number;
        let rangeMax = '0' as string;

        rangeMax = (slidesLength - 1).toString();

        input.max = rangeMax;

        setRangeThumbWidth(rangeMax, input);
    };

    const setRangeThumbWidth = (max: string, input: HTMLInputElement) => {
        const width = 94 / +max;
        input.style.setProperty('--thumb-size', width.toString() + 'px');

        if (max === '1') {
            input.style.setProperty('--thumb-size', '47px');
        }
    };

    const handleOverlay = (card: Element, index: number, overlays: NodeListOf<HTMLDivElement>) => {
        let activeIndex = index;

        card.addEventListener('click', () => {
            overlays[activeIndex].classList.add('recipient-detail__overlay--active');
            footer.classList.add("hidden")
        });

        card.addEventListener('keydown', (e) => {
            const keyboardEvent = <KeyboardEvent>e;

            if (keyboardEvent.key === 'Enter') {
                overlays[activeIndex].classList.add('recipient-detail__overlay--active');
            }
        });

        if (overlays[activeIndex]) {
            overlays[activeIndex].addEventListener('click', (e) => {
                const targetElement = e.target as HTMLElement;

                if (targetElement.classList.contains('recipient-detail__overlay')) {
                    overlays[activeIndex].classList.remove('recipient-detail__overlay--active');
                    setTimeout(() => {
                        dashboardRight.classList.remove("dashboard__right--hidden");
                        footer.classList.remove("hidden")
                    }, 200);
                }
            });
        }
    };

    closeButtons.forEach((button) => {
        button.addEventListener('click', () => {
            button.parentElement.parentElement.parentElement.parentElement.classList.remove(
                'recipient-detail__overlay--active',
            );

            setTimeout(() => {
                dashboardRight.classList.remove("dashboard__right--hidden");
                footer.classList.remove("hidden")
            }, 200);
        });
    });

    dashboardPaymentCarousels.forEach((carousel: HTMLDivElement, i: number) => {
        if (i === 0) {
            var glide = new Glide(carousel, {
                type: 'slider',
                perView: 1,
                gap: 30,
                rewind: false,
                bound: false,
            });

            glide.mount();

            carousel.setAttribute("data-history-carousel", "true")
        }

        if (i === 1) {
            var glide = new Glide(carousel, {
                type: 'slider',
                perView: 1.3,
                gap: 11,
                rewind: false,
                bound: true,
            });

            glide.mount();

            const carouselRange = dashboard.querySelector('.dashboard__range') as HTMLInputElement;
            const slides: NodeListOf<Element> = contactsCarousel.querySelectorAll('.dashboard-contact');

            carouselRange.addEventListener('input', () => {
                setTimeout(() => {
                    glide.go(`=${carouselRange.value}`);
                }, 500);
            });

            glide.on('move.after', () => {
                const currentIndex = glide.index as string;
                carouselRange.value = currentIndex;
            });

            setRangeMax(slides, carouselRange);
        }
    });

    dashboardCardsCarousel.forEach((card, index: number) => {
        handleOverlay(card, index, dashboardCardsCarouselOverlays);
    });

    contactsCarouselCards.forEach((card, index: number) => {
        handleOverlay(card, index, contactsCarouselOverlays);
    });

    contactCards.forEach((card, index: number) => {
        handleOverlay(card, index, contactCardsOverlays);
    });

    dashboardCards.forEach((card, index: number) => {
        handleOverlay(card, index, dashboardCardsOverlays);
    });

    clickAndDrag('.dashboard__links');
}