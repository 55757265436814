const video: HTMLVideoElement | null = document.querySelector(".quote__video");
let alreadyActive = false;

if (video) {
    const observer: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting && !alreadyActive) {
                video.play();
                alreadyActive = true;
            }
        });
    }, { threshold: 0.5 });

    observer.observe(video);
}