import countries from "./countries.json";
import axios from "axios";
import { UpdatePhonePayload, UpdatePhoneResponse } from "./phoneModal.types";

const phoneButtons = document.querySelectorAll(".modal--phone-button") as NodeListOf<HTMLButtonElement>;
const phoneModals = document.querySelectorAll(".modal--phone") as NodeListOf<HTMLDivElement>;
const phoneLabels = document.querySelectorAll(".values--phone") as NodeListOf<HTMLSpanElement>;
const readOnlyPhoneInput = document.querySelector(".phone-input.readonly") as HTMLInputElement;
const amanacardNumber = document.getElementById("cardNumber") as HTMLParagraphElement;
const phoneNumberHiddenInput = document.querySelector('input[name="newPhoneNumber"]') as HTMLInputElement;

const getCountryCode = (countryName: string) => {
  const country = countries.find(country => country["Country Name"].toLowerCase() === countryName.toLowerCase());
  return country ? country["ISO2"] : "";
}

const updatePhoneNumber = async (payload: UpdatePhonePayload): Promise<UpdatePhoneResponse> => {
  try {
    // For development:
    // const response = await axios.post<UpdatePhoneResponse>("https://localhost:44323/api/recipients/updateRecipientPhoneNumber", payload);

    if (phoneNumberHiddenInput && payload.newNumber !== null && payload.newNumber !== undefined) {
      phoneNumberHiddenInput.value = payload.newNumber;
      return {
        success: true,
        message: "Phone number updated successfully."
      };
    }else {
      const response = await axios.post<UpdatePhoneResponse>("/api/recipients/updateRecipientPhoneNumber", payload);
      return response.data;
    }
    
  } catch (exception: any) {
    console.error(exception);

    return {
      success: false,
      message: "An error occurred while updating the phone number."
    }
  }
}

if (phoneButtons.length > 0 && phoneModals.length > 0 && phoneButtons.length === phoneModals.length) {
  phoneButtons.forEach((button, index) => {
    button.addEventListener("click", (e) => {
      phoneModals[index].classList.add("active");
      readOnlyPhoneInput.dispatchEvent(new Event('input'));
    });
  });

  phoneModals.forEach((modal) => {
    const { allowedCountry, currentNumber, successMessage, errorMessage, loadingMessage, uid } = modal.dataset;
    const modalButton = modal.querySelector(".modal-button") as HTMLButtonElement;
    const oldButtonHtml = modalButton.innerHTML;
    const closeButton = modal.querySelector(".close") as HTMLButtonElement;
    // 'State' variables
    let isLoading = false;
    let newNumber: string | null = null;

    const resetButton = () => {
      modalButton.innerHTML = oldButtonHtml;
      modalButton.disabled = false;
    }

    modal.addEventListener("click", (event) => {
      if (event.target === modal) {
        modal.classList.remove("active");
      }
    });

    if (closeButton) {
      closeButton.addEventListener("click", () => {
        modal.classList.remove("active");
      });
    }

    // Init intlTelInput
    const phoneInputs = modal.querySelectorAll(".phone-input") as NodeListOf<HTMLInputElement>;

    phoneInputs.forEach((input) => {
      // @ts-expect-error
      const iti = window.intlTelInput(input, {
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.4/build/js/utils.js",
        // onlyCountries: [getCountryCode(allowedCountry)],
        autoHideDialCode: false,
        nationalMode: false
      });

      // Set the country
      iti.setCountry(getCountryCode(allowedCountry));

      if (input.classList.contains('readonly')) {
        input.disabled = true;
        input.value = currentNumber;
        //manually triggering the input event to update the current number
        //as this is pre-populated in the cshtml
        input.dispatchEvent(new Event('input'));
      }

      input.addEventListener('input', () => {
        newNumber = iti.getNumber();

        modalButton.disabled = !iti.isValidNumber();
      });

      const updateUI = (success: boolean | null = null) => {
        input.disabled = isLoading;
        if (isLoading) {
          modalButton.disabled = true;
          modalButton.textContent = loadingMessage;
          input.classList.add("readonly");
        }
        else if (success === true) {
          modalButton.textContent = successMessage;
          input.disabled = true;
          input.classList.add("readonly");

          phoneLabels.forEach((label) => {
            label.textContent = newNumber;
          });

          setTimeout(() => {
            modal.classList.remove("active");
            phoneInputs.forEach((input) => {
              input.value = newNumber;
            });
            resetButton();
          }, 2500);
        }
        else if (success === false) {
          modalButton.textContent = errorMessage;
          input.disabled = true;
          input.classList.add("readonly");

          setTimeout(() => {
            modal.classList.remove("active");
            resetButton();
          }, 2500);
        }
      }

      modalButton.addEventListener("click", async () => {
        if (isLoading) return;

        isLoading = true;
        updateUI();

        const payload: UpdatePhonePayload = {
          uid: parseInt(uid),
          newNumber: newNumber,
          amanacardNumber: amanacardNumber?.textContent.trim() as string ?? ""
        };

        const response = await updatePhoneNumber(payload);
        isLoading = false;

        updateUI(response.success);
      });
    });
  });
}