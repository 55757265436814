const mobileMenuIcon = document.querySelector(".menu-mobile__icon") as HTMLElement
const mobileMenuIconContainer = document.querySelector(".menu-mobile__icon-container") as HTMLElement
const mobileMenuButton = document.querySelector(".menu-mobile__icon-container") as HTMLElement
const mobileMenuBackButton = document.querySelector(".menu-mobile__back") as HTMLElement
const getInTouchButton = document.querySelector(".menu-mobile__button") as HTMLElement
const mobileMenuOverlay = document.querySelector(".menu-mobile__overlay") as HTMLElement
const mobileMenuNested = document.querySelector(".menu-mobile__nested") as HTMLElement
const html = document.querySelector("html") as HTMLElement

if(mobileMenuIconContainer){
  mobileMenuIconContainer.addEventListener("click", e => {

    if(mobileMenuIcon.classList.contains("menu-mobile__icon--open")){
      mobileMenuIcon.classList.remove("menu-mobile__icon--open")
      mobileMenuOverlay.classList.remove("menu-mobile__overlay--active")
      setTimeout(() => {
        mobileMenuNested.classList.remove("menu-mobile__nested--active")
      },500)
      html.style.overflow="auto"
    } else {
      mobileMenuIcon.classList.add("menu-mobile__icon--open")
      mobileMenuOverlay.classList.add("menu-mobile__overlay--active")
      html.style.overflow="hidden"
    }
  })
}

if(mobileMenuButton){
  mobileMenuButton.addEventListener("keydown", e => {
    if(e.key === "Enter"){
      if(mobileMenuIcon.classList.contains("menu-mobile__icon--open")){
        mobileMenuIcon.classList.remove("menu-mobile__icon--open")
        mobileMenuOverlay.classList.remove("menu-mobile__overlay--active")
        setTimeout(() => {
          mobileMenuNested.classList.remove("menu-mobile__nested--active")
        },500)
        html.style.overflow="auto"
      } else {
        mobileMenuIcon.classList.add("menu-mobile__icon--open")
        mobileMenuOverlay.classList.add("menu-mobile__overlay--active")
        html.style.overflow="hidden"
      }
    }
  })
}

if(mobileMenuOverlay){
  mobileMenuOverlay.addEventListener("keydown", e => {
    if(e.key === "Escape"){
      mobileMenuIcon.classList.remove("menu-mobile__icon--open")
      mobileMenuOverlay.classList.remove("menu-mobile__overlay--active")
      setTimeout(() => {
        mobileMenuNested.classList.remove("menu-mobile__nested--active")
      },500)
      html.style.overflow="auto"
    }
  })
  
  mobileMenuOverlay.addEventListener("click", e => {
    const targetElement = e.target as HTMLElement;
  
    if (targetElement.className === "menu-mobile__overlay menu-mobile__overlay--active") {
      mobileMenuIcon.classList.remove("menu-mobile__icon--open")
      mobileMenuOverlay.classList.remove("menu-mobile__overlay--active")
      setTimeout(() => {
        mobileMenuNested.classList.remove("menu-mobile__nested--active")
      },500)
      html.style.overflow="auto"
    }
  })
}

if(getInTouchButton){
  getInTouchButton.addEventListener("click", e => {
    mobileMenuNested.classList.add("menu-mobile__nested--active")
  })
}

if(mobileMenuBackButton){
  mobileMenuBackButton.addEventListener("click", e => {
    mobileMenuNested.classList.remove("menu-mobile__nested--active")
  })
}