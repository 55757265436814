const setMaxDateValue = () => {
  const today: Date = new Date();

  const eighteenYearsAgo: Date = today;
  eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

  const isLeapYear = (year: number): boolean => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

  if (today.getMonth() === 1 && today.getDate() === 29 && !isLeapYear(today.getFullYear())) {
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 1);
  }

  const maxDate: string = eighteenYearsAgo.toISOString().split('T')[0];

  return maxDate;
}

export default setMaxDateValue;
