const featureDownloadButton = document.querySelector(".feature-with-media__button") as HTMLElement

if (featureDownloadButton) {
  const featureDownloadMedia = (url: string, fileName: string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  const onDownloadButtonClick = () => {
    const mediaUrl = featureDownloadButton.getAttribute("data-url")
    const mediaFileName = featureDownloadButton.getAttribute("data-label");

    featureDownloadMedia(mediaUrl, mediaFileName);
  }

  featureDownloadButton.addEventListener('click', onDownloadButtonClick);
}